<div class="section">
  <span class="section-icon">
    <mat-icon [ngClass]="{ 'completed-icon': complete }" data-testid="step-complete-indicator">{{
      complete ? 'check_circle' : 'radio_button_unchecked'
    }}</mat-icon>
  </span>
  <h2
    class="section-title"
    [ngClass]="{ standalone: standaloneSection, nested: !standaloneSection }"
    matListItemLine
    data-testid="section-description"
  >
    {{ title }}
  </h2>
  <span class="section-action">
    <button *ngIf="!complete" (click)="onSectionClick()" mat-raised-button color="primary" data-testid="start-button">Start</button>
    <a
      class="section-control"
      *ngIf="complete"
      (click)="onRestartClick()"
      (keydown.enter)="onRestartClick()"
      data-testid="restart-button"
      role="button"
      tabindex="0"
      >Restart</a
    >
  </span>
</div>
