<div>
  <div class="upload-header-text" *ngIf="displayHeader">Upload Document(s)</div>
  <dmv-shared-feature-document-upload-helper-text
    [coRegistrantDocPresent]="false"
    [showInsuranceBullet]="false"
    [isEnhanced]="enhancedId"
    [showNotes]="showNotes"
    data-testid="upload-helper-text"
  >
  </dmv-shared-feature-document-upload-helper-text>
  <div id="document-upload-wrapper">
    <dmv-shared-feature-multi-upload-panels
      #mandatoryUploadPanels
      *ngIf="existingUploadItems && existingUploadItems.length > 0"
      [uploadItems]="existingUploadItems"
      [transactionId]="transactionId"
      [transactionType]="transactionType"
      [transactionData]="transactionData"
      (checkDocTypeChangeEligible)="onCheckDocTypeChangeEligible($event)"
      (docTypeChangeClicked)="onDocTypeChangeClicked($event)"
      (allDocumentUploadsValid)="updateDocumentUploadsValid($event)"
      (event)="uploadEventDone($event)"
      (panelItemUploaded)="panelItemUploaded($event)"
      (uploadDetailReuploaded)="onUploadDetailReuploaded($event)"
    >
    </dmv-shared-feature-multi-upload-panels>
  </div>
</div>
