import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-section-v2',
  styleUrls: ['./section-v2.component.scss'],
  templateUrl: './section-v2.component.html',
})
export class SectionV2Component {
  @Input() public complete = false;
  @Input() public standaloneSection = false;
  @Input() public title = '';
  @Output() public readonly restartClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly sectionClick: EventEmitter<void> = new EventEmitter<void>();

  public onRestartClick() {
    this.restartClick.emit();
  }

  public onSectionClick() {
    this.sectionClick.emit();
  }
}
