<div class="barcode-container">
  <div class="transaction-id-label" *ngIf="applicationName">
    Your application for <span>{{ applicationName }}</span
    ><br />
    Transaction ID: <span>{{ transactionId }}</span>
  </div>
  <img #barCodeImage *ngIf="!errorMessage" alt="Barcode" />

  <div *ngIf="errorMessage">
    <section class="inspection-info" id="error-information" *ngIf="errorMessage">
      <h4 class="text-danger">
        {{ errorMessage }}
      </h4>
    </section>
  </div>
</div>
