import {
  AnswerPrompt,
  Document,
  DocumentCategory,
  DocumentSide,
  DocumentsReview,
  DocumentType,
  DocumentTypes,
  Question,
  Status,
  StepType,
  SurveyFunctionContext,
  SurveySectionState,
  SurveySectionType,
  SurveySectionTypeLabel,
  UploadDocumentDetail,
  UploadItem,
  UsedDocumentTypes,
} from '@dmv/public/shared/http';
import { sortPrompts } from './sort-prompts.helper';

const BACK_IMAGE_INSTRUCTION = 'You cannot use the same file name as above.';
export const NO_SECOND_PROOF_TITLE = 'I do not have a second proof of residency';
export const NO_PROOF_TITLE = 'I do not have any proofs of residency';
const SAMPLE_BACK = 'https://storage.googleapis.com/dmv-digital-intake-dev-logos/document-back.png';
const SAMPLE_FRONT = 'https://storage.googleapis.com/dmv-digital-intake-dev-logos/document-front.png';
export const confirmationErrorMessage = 'Please confirm that you have reviewed your information by checking the checkbox below';

export const proofSectionProTip = `
    <ul class="toast-content-list">
      <li>If you do not have your document ready to upload, you can work on a different section by clicking on the menu above or go back to the Checklist.
      </li>
    </ul>`;

export const noSocialSecurityCardOption = 'Social Security Number without the card (number must be printed on MV-44)';
export function buildUploadItem(
  item: DocumentTypes,
  parentDocumentTypeId?: number,
  reusableDocumentType?: UsedDocumentTypes,
  sectionComplete?: boolean,
): UploadItem {
  const requiredDocument: UploadItem = {
    details: [
      {
        classificationScoreDocAiResults: [],
        docTypeChangeEligible: false,
        documentTypeId: item.id as unknown as number,
        id: `${item.name}-front`,
        instructions: item.instructions,
        isSample: !item.customPreview,
        loading: false,
        parentDocumentTypeId,
        placeHolderUrl: item.customImage ? item.customImage : SAMPLE_FRONT,
        qualityScoreDocAiResults: [],
        required: true,
        side: DocumentSide.FRONT,
        subTitle: item.hasBack ? `${item.name} Front` : item.name,
        uploaded: false,
      },
    ],
    documentTypeId: item.id as unknown as number,
    id: `document-${item.id}`,
    link: item.link,
    originalStageIds: [],
    parentDocumentTypeId,
    sequentialUpload: item.sequentialUpload,
    shortName: item.shortName,
    title: item.name,
    uploadResponseData: [],
  };
  if (item.hasBack) {
    requiredDocument.singleFileChoose = true;
    requiredDocument.details.push({
      classificationScoreDocAiResults: [],
      docTypeChangeEligible: false,
      documentTypeId: item.id as unknown as number,
      id: `${item.name}-back`,
      instructions: BACK_IMAGE_INSTRUCTION,
      loading: false,
      placeHolderUrl: SAMPLE_BACK,
      qualityScoreDocAiResults: [],
      required: true,
      side: DocumentSide.BACK,
      subTitle: `${item.name} Back`,
      uploaded: false,
    });
  }

  // If a match is found, update originalStageIds, else return the item unchanged
  // Check section status to determine if these properties should be set as the user cannot reuse documents on restarted sections
  if (reusableDocumentType && !sectionComplete) {
    requiredDocument.uploaded = true;
    requiredDocument.uploadedDocumentId = reusableDocumentType.documentId;
  }

  return requiredDocument;
}

function getMatchingUsedDocumentType(usedDocumentTypes: UsedDocumentTypes[], surveySectionType: SurveySectionType, documentTypeId: number) {
  return usedDocumentTypes?.find(usedDocumentType => {
    return surveySectionType
      ? usedDocumentType.documentTypeId === documentTypeId && usedDocumentType.surveySectionTypes.includes(surveySectionType)
      : usedDocumentType.documentTypeId === documentTypeId;
  });
}

/**
 * Build upload items for the upload step. Factor in reusable documents if we have any.
 *
 * @param documentTypes the list of root document types that may contain child document types
 * @param surveySectionType
 * @param usedDocumentTypes an optional array of UsedDocumentTypes that can be used to prevent re-upload of reusable documents.
 * @param existingUploadItems an optional array of already uploaded items that should be used to update the new UploadItem instance
 */
export function buildUploadItems(
  documentTypes: DocumentTypes[],
  usedDocumentTypes?: UsedDocumentTypes[],
  surveySectionType?: SurveySectionType,
  existingUploadItems?: UploadItem[],
  sectionComplete?: boolean,
): UploadItem[] {
  const uploadItems: UploadItem[] = [];

  documentTypes.forEach(documentType => {
    if (documentType.childDocumentTypes) {
      for (const childDocumentType of documentType.childDocumentTypes) {
        uploadItems.push(
          buildUploadItem(
            childDocumentType,
            documentType.id,
            getMatchingUsedDocumentType(usedDocumentTypes, surveySectionType, childDocumentType.id),
            sectionComplete,
          ),
        );
      }
    } else {
      uploadItems.push(
        buildUploadItem(
          documentType,
          null,
          getMatchingUsedDocumentType(usedDocumentTypes, surveySectionType, documentType.id),
          sectionComplete,
        ),
      );
    }
  });
  // Render existingUploadItems to current items
  if (existingUploadItems && existingUploadItems.length > 0) {
    for (const [i, existingUploadItem] of existingUploadItems.entries()) {
      const existingUploadItemTitle = existingUploadItem.title;
      const indexOfDoc = uploadItems.findIndex(uploadItem => uploadItem.title === existingUploadItemTitle);
      if (indexOfDoc >= 0) {
        uploadItems[indexOfDoc] = existingUploadItems[i];
      }
    }
  }

  return uploadItems;
}

/**
 * Build review objects from the set of known survey section questions and uploads
 *
 * Note: Intended for proof sections where all questions are known, and we build answers from document types.
 * Or from auto-proceed where the user has not actually uploaded for this section
 *
 * @param questions
 * @param uploadItems
 */
export function buildSectionReview(questions: Question[], uploadItems: UploadItem[]): DocumentsReview {
  const docDetails = filterDetails(uploadItems);

  return {
    questions: questions
      .filter(question => question.answers.length > 0)
      .map(question => {
        const answers: string[] = [];
        for (const answer of question.answers) {
          answers.push(formatQuestionAnswer(answer, uploadItems));
        }

        return {
          answer: question.answers.every(answer => !isNaN(parseInt(answer))) ? null : answers.join(', '),
          showList: question.answers.every(answer => !isNaN(parseInt(answer))),
          title: question.question,
        };
      }),
    uploads: { documents: prepareReviewDocuments(docDetails, uploadItems), title: 'Document(s)' },
  };
}

/**
 * Build review objects for dynamic survey sections where the questions list is determined by each answer
 *
 * @param questionAnswers
 * @param uploadItems The currently uploaded items
 */
export function buildSectionReviewFromAnswers(
  questionAnswers: Record<string, { answerId: string; answers: string[]; questionPrompt: string }>,
  uploadItems: UploadItem[],
): DocumentsReview {
  const docDetails = filterDetails(uploadItems);

  return {
    questions: Object.values(questionAnswers).map(answerContext => {
      const answers: string[] = [];
      for (const answer of answerContext.answers) {
        answers.push(formatQuestionAnswer(answer, uploadItems));
      }

      return {
        answer: answerContext.answers.every(answer => !isNaN(parseInt(answer))) ? null : answers.join(', '),
        showList: answerContext.answers.every(answer => !isNaN(parseInt(answer))),
        title: answerContext.questionPrompt,
      };
    }),
    uploads: { documents: prepareReviewDocuments(docDetails, uploadItems), title: 'Document(s)' },
  };
}

export function formatQuestionAnswer(answer: string, uploadItems: UploadItem[]): string {
  if (!isNaN(parseInt(answer))) {
    // This could be an answer that does parse to a number, but does not yield a document.
    const foundDocByAnswerId = uploadItems.find(item => item.documentTypeId === parseInt(answer));

    return foundDocByAnswerId ? foundDocByAnswerId.title : answer;
  } else {
    return answer;
  }
}

function filterDetails(uploadItems: UploadItem[]): UploadDocumentDetail[] {
  const docDetails: UploadDocumentDetail[] = [];
  for (const upload of uploadItems) {
    for (const details of upload.details) {
      docDetails.push(details);
    }
  }

  return docDetails;
}

function prepareReviewDocuments(items: UploadDocumentDetail[], uploadItems: UploadItem[]): Document[] {
  return items.map(item => {
    const foundDocByAnswerId = uploadItems.find(uploadItem => uploadItem.documentTypeId === item.documentTypeId);
    let title = item.subTitle.replace(' Front', '');

    if (title && !title.toLowerCase().includes('previously uploaded') && foundDocByAnswerId.uploadedDocumentId) {
      title += ' (previously uploaded)';
    }

    return {
      imgSrc: Array.isArray(item.imageSrc) ? item.imageSrc : [],
      listDisplay: !item.subTitle.includes('Back'),
      title: title,
    };
  });
}
function getPreviouslyUsedNote(matchingAnswerPrompts: AnswerPrompt[], surveySectionType: SurveySectionType): string[] {
  return matchingAnswerPrompts.map(
    ap =>
      `<span>You have previously uploaded a ${ap.prompt} for your ${SurveySectionTypeLabel.get(surveySectionType)}.
      You can reuse that document for this section.</span>`,
  );
}
function evaluateTotalPoints(
  hasPreviouslyUsedUpload: boolean,
  surveySectionType: SurveySectionType,
  questions: Question[],
  preSelectedAnswerPrompts: AnswerPrompt[],
) {
  return hasPreviouslyUsedUpload
    ? questions[0].totalPoints +
        preSelectedAnswerPrompts.reduce((accumulator, ap) => {
          // Metadata on documents will have the point value from the database.  However, PoR is not a points threshold section.
          let points = 1;
          if (surveySectionType !== SurveySectionType.PROOF_OF_RESIDENCY && ap.metadata) {
            points = ap.metadata.points || 1;
          }

          return accumulator + points;
        }, 0)
    : questions[0].totalPoints;
}

/**
 * Tells the caller if an answer prompt aligns with a UsedDocumentType
 *
 * @param usedDocumentTypes
 * @param answerPrompt
 * @param surveySectionType
 */
export function isMatchingUsedDocumentType(
  usedDocumentTypes: UsedDocumentTypes[],
  answerPrompt: AnswerPrompt,
  surveySectionType: SurveySectionType,
): boolean {
  return usedDocumentTypes.some(
    usedDocumentType =>
      (usedDocumentType.parentDocumentTypeId === +answerPrompt.value || usedDocumentType.documentTypeId === +answerPrompt.value) &&
      usedDocumentType.surveySectionTypes.includes(surveySectionType),
  );
}

export const previouslyUsed = (
  answerPrompts: AnswerPrompt[],
  usedDocumentTypes: UsedDocumentTypes[],
  surveySectionType: SurveySectionType,
): boolean => answerPrompts.some(answerPrompt => isMatchingUsedDocumentType(usedDocumentTypes, answerPrompt, surveySectionType));

export const findMatchingAnswerPrompts = (
  answerPrompts: AnswerPrompt[],
  usedDocumentTypes: UsedDocumentTypes[],
  surveySectionType: SurveySectionType,
): AnswerPrompt[] => answerPrompts.filter(answerPrompt => isMatchingUsedDocumentType(usedDocumentTypes, answerPrompt, surveySectionType));

export const findMatchingReusableDocument = (
  usedDocumentTypes: UsedDocumentTypes[],
  answerPrompt: AnswerPrompt,
  surveySectionType: SurveySectionType,
): UsedDocumentTypes | undefined => {
  return usedDocumentTypes.find(
    usedDocumentType =>
      (usedDocumentType.parentDocumentTypeId === +answerPrompt.value || usedDocumentType.documentTypeId === +answerPrompt.value) &&
      usedDocumentType.surveySectionTypes.includes(surveySectionType),
  );
};

function buildAnswerPrompt(
  docType: DocumentTypes,
  answerPromptDocumentIds: number[],
  documentTypeId: string,
  surveySectionType: SurveySectionType,
): AnswerPrompt {
  return {
    additionalInfo: docType.additionalInfo,
    documentIds: answerPromptDocumentIds,
    id: documentTypeId,
    instructions: docType.instructions,
    metadata: docType.metadata
      ? {
          ...docType.metadata,
          idUpgradeEligible:
            surveySectionType === SurveySectionType.PROOF_OF_BIRTH && docType.metadata.idUpgradeEligibleOpOverride
              ? !docType.metadata.idUpgradeEligibleOpOverride
              : docType.metadata.idUpgradeEligible,
        }
      : {},
    name: docType.name,
    order: docType.metadata?.popularity || 0,
    popularity: docType.metadata?.popularity || 0,
    prompt: docType.name,
    value: documentTypeId,
  } as AnswerPrompt;
}

export function buildAnswerPrompts(
  docTypes: DocumentTypes[],
  usedDocumentTypes: UsedDocumentTypes[],
  surveySectionType: SurveySectionType,
): AnswerPrompt[] {
  const prompts: AnswerPrompt[] = [];
  for (const docType of docTypes) {
    const documentTypeId: string = docType.id?.toString() || '';
    // Just set the parent (or root) document type's ID as the required document IDs
    // At time of upload item build, we will check to see if that DocumentTypes object has children
    // If it does, build upload items from the children only - otherwise build only the "parent"
    const answerPromptDocumentTypeIds: number[] = [docType.id];
    prompts.push(buildAnswerPrompt(docType, answerPromptDocumentTypeIds, documentTypeId, surveySectionType));
  }
  const applicableAnswers = findMatchingAnswerPrompts(prompts, usedDocumentTypes, surveySectionType);

  for (const applicableAnswer of applicableAnswers) {
    applicableAnswer.prompt = `${applicableAnswer.prompt} (previously uploaded)`;

    applicableAnswer.previouslyUsedUpload = true;
    applicableAnswer.previouslyUsedDocumentId =
      findMatchingReusableDocument(usedDocumentTypes, applicableAnswer, surveySectionType)?.documentId || undefined;
  }

  return sortPrompts(prompts, usedDocumentTypes);
}
export function initializeProofSection<T extends SurveySectionState>(
  initialState: T,
  surveySectionType: SurveySectionType,
  sectionStatus: Status,
  docTypes: DocumentTypes[],
  usedDocumentTypes: UsedDocumentTypes[] | null,
  questionIndex = 0,
): SurveySectionState {
  const applicableDocuments: UsedDocumentTypes[] =
    usedDocumentTypes?.filter(
      previouslyUploadedDoc => previouslyUploadedDoc.surveySectionTypes.includes(surveySectionType) && sectionStatus !== 'complete',
    ) || [];
  const updatedAnswerPrompts = buildAnswerPrompts(docTypes, applicableDocuments, surveySectionType);
  const hasPreviouslyUsedUpload = previouslyUsed(updatedAnswerPrompts, applicableDocuments, surveySectionType);

  const preSelectedAnswerPrompts = findMatchingAnswerPrompts(updatedAnswerPrompts, applicableDocuments, surveySectionType);

  const questions = [...initialState.surveyWrapperState.questions];

  if (questions[questionIndex]) {
    questions[questionIndex] = {
      ...initialState.surveyWrapperState.questions[questionIndex],
      answerPrompts: [...updatedAnswerPrompts, ...(initialState.surveyWrapperState.questions[questionIndex].answerPrompts || [])],
      answers: hasPreviouslyUsedUpload
        ? [...initialState.surveyWrapperState.questions[questionIndex].answers, ...preSelectedAnswerPrompts.map(ap => ap.value)]
        : initialState.surveyWrapperState.questions[questionIndex].answers,
      hasPreviouslyUsedUpload: hasPreviouslyUsedUpload,
      notes: hasPreviouslyUsedUpload
        ? [
            ...(initialState.surveyWrapperState.questions[questionIndex].notes || []),
            ...getPreviouslyUsedNote(preSelectedAnswerPrompts, surveySectionType),
          ]
        : [],
      totalPoints: evaluateTotalPoints(
        hasPreviouslyUsedUpload,
        surveySectionType,
        initialState.surveyWrapperState.questions,
        preSelectedAnswerPrompts,
      ),
    };
  }

  return {
    ...initialState,
    surveyWrapperState: {
      ...initialState.surveyWrapperState,
      questions,
      requiredDocumentIds: [...initialState.surveyWrapperState.requiredDocumentIds],
      tempRequiredDocumentIds: questions.reduce((accumulator, currentQuestion) => {
        return accumulator.concat(currentQuestion.answers.map(answer => +answer));
      }, [] as number[]),
    },
    uploadItems: [],
  };
}

/**
 * Derives the SurveySectionType based on the document category name.
 * Used for Additional Information.
 * @param categoryName
 */
export const getSurveySectionType = (categoryName: string): SurveySectionType | null => {
  let surveySectionType: SurveySectionType | null = null;
  switch (categoryName as DocumentCategory) {
    case 'Current Id':
      surveySectionType = SurveySectionType.CURRENT_ID;
      break;
    case 'Current License':
      surveySectionType = SurveySectionType.CURRENT_LICENSE;
      break;
    case 'Proof of Reduced or No Fee Eligibility':
      surveySectionType = SurveySectionType.FEE_ELIGIBILITY;
      break;
    case 'Lawful Status':
      surveySectionType = SurveySectionType.LAWFUL_STATUS;
      break;
    case 'MV-44':
      surveySectionType = SurveySectionType.MV44;
      break;
    case 'Proof of Birth':
      surveySectionType = SurveySectionType.PROOF_OF_BIRTH;
      break;
    case 'Proof of Identity':
      surveySectionType = SurveySectionType.PROOF_OF_IDENTITY;
      break;
    case 'Proof of Legal Name':
      surveySectionType = SurveySectionType.LEGAL_NAME;
      break;
    case 'Proof of Name':
      surveySectionType = SurveySectionType.PROOF_OF_NAME;
      break;
    case 'Proof of Residency':
      surveySectionType = SurveySectionType.PROOF_OF_RESIDENCY;
      break;
    case 'Proof of Social Security':
      surveySectionType = SurveySectionType.PROOF_OF_SOCIAL_SECURITY;
      break;
    default:
      break;
  }

  return surveySectionType;
};

export function filterPreviouslyUploadedDocuments(
  currentSection: SurveySectionType,
  usedDocumentTypes: UsedDocumentTypes[],
  uploadItems: UploadItem[],
) {
  const previouslyUsedDocumentTypeIds: number[] = usedDocumentTypes
    .filter(usedDocumentType => usedDocumentType.surveySectionTypes.includes(currentSection))
    .map(usedDocumentType => usedDocumentType.documentTypeId);

  return uploadItems.filter(item => !previouslyUsedDocumentTypeIds.includes(item.documentTypeId));
}

export const BOAT_OWNERSHIP_ADDITIONAL_INFO = `
At this time, we do not support other proofs of ownership online. You can read about how to register your vehicle on the
<a href="https://dmv.ny.gov/registration/how-register-vehicle" target="_blank">
  DMV website
  <span class="material-icons open-new-icon">open_in_new</span>
</a>,
or you can read about specific situations:
<ul>
  <li>
    <a href="https://dmv.ny.gov/registration/register-boat" target="_blank">
    USCG Certificate of Documentation (Form CG-1270)
      <span class="material-icons open-new-icon">open_in_new</span>
    </a>
  </li>
  <li>
    <a href="https://dmv.ny.gov/registration/abandoned-vehicles" target="_blank">
    Garageman's Lien
      <span class="material-icons open-new-icon">open_in_new</span>
    </a>
  </li>
  <li>
    <a href="https://dmv.ny.gov/registration/proof-ownership-not-available" target="_blank">
    No proof of ownership
      <span class="material-icons open-new-icon">open_in_new</span>
    </a>
  </li>
<ul>`;

export const SNOWMOBILE_OWNERSHIP_ADDITIONAL_INFO = `
At this time, we do not support other proofs of ownership online. You can read about how to register your vehicle on the
<a href="https://dmv.ny.gov/registration/how-register-vehicle" target="_blank">
  DMV website
  <span class="material-icons open-new-icon">open_in_new</span>
</a>,
or you can read about specific situations:
<ul>
  <li>
    <a href="https://dmv.ny.gov/registration/proof-ownership-not-available" target="_blank">
      No proof of ownership
      <span class="material-icons open-new-icon">open_in_new</span>
    </a>
  </li>
<ul>`;

export function getExpiredDate(): string {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 2);
  const dateString = date.toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });

  return dateString;
}

export function hasCurrentIdQuestion(questionText?: string): Question {
  let question = `Do you have a NY State Driver License, Permit or Non-Driver ID that is current or not expired for more than 2 years (expired after ${getExpiredDate()})?`;

  if (questionText) {
    question = `${questionText} that is current or not expired for more than 2 years (expired after ${getExpiredDate()})?`;
  }

  return {
    answerPrompts: [
      {
        documentIds: [DocumentType.LICENSE_PERMIT_NON_DRIVER_ID],
        id: 'has-current-id-yes',
        metadata: { idUpgradeEligible: true, points: 1 },
        prompt: 'Yes',
        value: 'Yes',
      },
      {
        documentIds: [],
        id: 'has-current-id-no',
        metadata: { idUpgradeEligible: true, points: 1 },
        prompt: 'No',
        value: 'No',
      },
    ],
    answers: [],
    id: 'has-current-id',
    minPoints: 1,
    name: 'has-current-id',
    question,
    stepName: 'survey',
    stepSectionFlowName: 'originalRegistration',
    stepSectionName: 'has-current-id',
    totalPoints: 0,
    type: 'radio',
  } as Question;
}

export const SAME_NAME_QUESTION_ID = 'proof-of-name-same-name';

export function getProofOfBirthOrNameNextStep(
  answers: string[],
  activeStep: StepType,
  questionId: string,
  sectionId: SurveySectionType,
  functionContext: SurveyFunctionContext,
  nextSteps: Map<string, StepType>,
) {
  let hasAllUploads = false;
  if (
    (questionId === SAME_NAME_QUESTION_ID &&
      [
        SurveySectionType.REGISTRANT_PROOF_OF_NAME,
        SurveySectionType.CO_REGISTRANT_PROOF_OF_NAME,
        SurveySectionType.OWNER_PROOF_OF_NAME,
      ].includes(sectionId)) ||
    [
      SurveySectionType.CO_REGISTRANT_PROOF_OF_BIRTH,
      SurveySectionType.REGISTRANT_PROOF_OF_BIRTH,
      SurveySectionType.OWNER_PROOF_OF_BIRTH,
    ].includes(sectionId)
  ) {
    hasAllUploads = answers.every(answer =>
      functionContext.usedDocumentTypes
        .map(udt => ({
          documentTypeId: String(udt.documentTypeId),
          parentDocumentTypeId: udt.parentDocumentTypeId ? String(udt.parentDocumentTypeId) : null,
          surveySectionTypes: udt.surveySectionTypes,
        }))
        .some(
          mappedUsedDocument =>
            (mappedUsedDocument.documentTypeId === answer || mappedUsedDocument.parentDocumentTypeId === answer) &&
            mappedUsedDocument.surveySectionTypes.includes(sectionId),
        ),
    );
  }
  let nextStep: StepType | null = null;
  if (activeStep && activeStep === 'survey' && hasAllUploads) {
    nextStep = 'review';
  } else if (activeStep && activeStep === 'survey' && !hasAllUploads) {
    nextStep = nextSteps.get(questionId);
  } else if (activeStep && activeStep === 'document_upload') {
    nextStep = 'review';
  }

  return nextStep;
}
