<div class="modal-body">
  <div class="table-responsive mt-3">
    <table class="table table-bordered">
      <thead>
        <tr class="bg-light">
          <th class="table-text" colspan="3">Features of Enhanced & REAL ID Documents</th>
        </tr>
      </thead>
      <tbody>
        <tr class="bg-light">
          <td></td>
          <td class="table-text text-center" scope="col">REAL ID</td>
          <td class="table-text text-center" scope="col">ENHANCED</td>
        </tr>
        <tr>
          <td class="table-text w-50" scope="row">Icon displayed on license</td>
          <td class="text-center">
            <img
              src="/assets/images/license-icon-real-id.jpg"
              alt="REAL ID icon black circle with a white star in the middle"
              title="REAL ID Logo"
            />
          </td>
          <td class="text-center">
            <img src="/assets/images/license-icon-enhanced.jpg" alt="Enhanced feature icon American flag" title="Enhanced ID Logo" />
          </td>
        </tr>
        <tr>
          <td class="table-text w-50" scope="row">Boarding a domestic (within U.S.) flight</td>
          <td class="text-center pt-4">
            <span class="cdk-visually-hidden" aria-label="REAL ID feature available"></span>
            <mat-icon class="check-icon" alt="Checkmark available icon">check_circle</mat-icon>
          </td>
          <td class="text-center pt-4">
            <span class="cdk-visually-hidden" aria-label="Enhanced feature available"></span>
            <mat-icon class="check-icon" alt="Checkmark available icon">check_circle</mat-icon>
          </td>
        </tr>
        <tr>
          <td class="table-text w-50" scope="row">
            Can use to enter U.S. at land crossing from Canada, Mexico, and some Caribbean countries
          </td>
          <td><span class="cdk-visually-hidden" aria-label="REAL ID feature un-available"></span></td>
          <td class="text-center pt-4">
            <span class="cdk-visually-hidden" aria-label="Enhanced feature available"></span>
            <mat-icon class="check-icon" aria-label="feature available" alt="Checkmark available">check_circle</mat-icon>
          </td>
        </tr>
        <tr>
          <td class="table-text w-50" scope="row">Cost $30 in addition to regular transaction fee</td>
          <td><span class="cdk-visually-hidden" aria-label="REAL ID feature un-available"></span></td>
          <td class="text-center pt-4">
            <span class="cdk-visually-hidden" aria-label="Enhanced feature available"></span>
            <mat-icon class="check-icon" alt="Checkmark available icon">check_circle</mat-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
