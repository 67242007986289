<form [formGroup]="form">
  <section *ngIf="question">
    <h2 class="survey-question" data-testid="survey-question">{{ question.question }}</h2>
    <div class="form-group text-form-group">
      <mat-form-field class="custom-form-field" appearance="outline">
        <input
          [id]="question.id"
          [maxLength]="question.maxLength"
          [placeholder]="question.placeholder"
          (blur)="onBlur($event, question.id, question.answerPrompts)"
          formControlName="answer"
          matInput
          attr.aria-labelledby="{{ question.question }}"
        />
      </mat-form-field>
    </div>
  </section>
</form>
