<div *ngIf="appointmentInformation" data-testid="appointment-information">
  <div class="font-weight-bold" data-testid="appointment-message">{{ appointmentMessage }}</div>
  <div class="date-container">
    <div data-testid="appointment-date">
      <span class="font-weight-bold appointment-header">Date:</span> {{ appointmentInformation.appointmentTime | date: 'EEEE MMMM dd, y' }}
    </div>
    <div data-testid="appointment-time">
      <span class="font-weight-bold appointment-header">Time:</span>
      {{ appointmentInformation.appointmentTime | date: 'h:mma':timezoneAbbr }} {{ timezoneAbbr }}
    </div>
  </div>
  <div data-testid="appointment-office-name">
    <span class="font-weight-bold appointment-header">Office Name:</span> {{ appointmentInformation.branchName }}
  </div>
  <div *ngIf="appointmentInformation.branchAddress; else missingAddress" data-testid="appointment-office-address">
    <span class="font-weight-bold appointment-header">Address:</span> {{ appointmentInformation.branchAddress }},
    {{ appointmentInformation.branchCity }}<br />
    <ng-content></ng-content>
  </div>

  <ng-template #missingAddress>
    <span class="font-weight-bold appointment-header">Address:</span>
    <p data-testid="missing-address">N/A</p>
  </ng-template>

  <div class="d-flex appointment-helpers-container">
    <div>
      <a
        class="calendar-link"
        [href]="appointmentInformation.calendarLink"
        data-testid="calendar-link"
        download="calendar.ics"
        aria-label="Add scheduled appointment to calendar"
      >
        <mat-icon class="calendar-icon">calendar_today</mat-icon>Add to Calendar
      </a>
    </div>
    <div>
      <a
        class="directions-link"
        [href]="appointmentInformation.mapsLink"
        data-testid="directions-link"
        target="_blank"
        rel="noopener"
        aria-label="Open directions in a new tab"
      >
        Get Directions <mat-icon class="open-new-icon">open_in_new</mat-icon></a
      >
    </div>
  </div>
  <dmv-dashboard-document-types
    *ngIf="appointmentInformation.txnDocTypes?.length > 0"
    [documentTypes]="appointmentInformation.txnDocTypes"
    [panelOpenState]="documentTypesPanelOpenState"
    [showSocialSecurityNotice]="showSocialSecurityNotice"
  >
  </dmv-dashboard-document-types>
  <div class="font-weight-bold pl-0" data-testid="qr-code-container">
    Upon arrival, please scan your
    <a
      class="qr-code-link"
      *ngIf="appointmentInformation.qrCodeLink; else noQrLink"
      [href]="appointmentInformation.qrCodeLink"
      [attr.aria-label]="'Opens your {{barcodeLinkText$ | async}} in a new tab'"
      data-testid="qr-code-link"
      target="_blank"
      rel="noopener"
      >{{ barcodeLinkText$ | async }}</a
    >
    <ng-template #noQrLink>{{ barcodeLinkText$ | async }}</ng-template>
    at an interactive touch screen display from your phone or a printout.
  </div>
</div>
