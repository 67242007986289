import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-terminal-page',
  styleUrls: ['./terminal-page.component.scss'],
  templateUrl: './terminal-page.component.html',
})
export class TerminalPageComponent {
  @Input() public summary = '';
  @Input() public continueText = 'Start Over';
  @Input() public previousText = 'Previous';
  @Input() public showAlert = false;
  @Input() public showPrevious = false;
  @Output() public readonly onContinue = new EventEmitter();
  @Output() public readonly onPrevious = new EventEmitter();

  public continueClick() {
    this.onContinue.emit();
  }
  public previousClick() {
    this.onPrevious.emit();
  }
}
