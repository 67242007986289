import { Directive, Host, Input, Optional } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { FormControlState } from 'ngrx-forms';

@Directive({
  // Will not work if this is renamed to dmv...
  // eslint-disable-next-line
  selector: '[ngrxFormControlState]',
})
export class FormErrorStateMatcherDirective {
  private _isInitial = true;

  constructor(@Host() @Optional() private readonly _input: MatInput, @Host() @Optional() private readonly _select: MatSelect) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public set ngrxFormControlState(state: FormControlState<any>) {
    const errorsAreShown = state.isInvalid && (state.isTouched || state.isSubmitted);
    if (this._input) {
      this._input.errorState = errorsAreShown;
      this._input.stateChanges.next();

      // force animation to fire. there an issue if masked input
      if (this._isInitial && state.isTouched) {
        this._input.autofilled = true;
        this._isInitial = false;
      }
    }

    if (this._select) {
      this._select.errorState = errorsAreShown;
      this._select.stateChanges.next();
    }
  }
}
