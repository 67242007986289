import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-accordion',
  styleUrls: ['./accordion.component.scss'],
  templateUrl: './accordion.component.html',
})
export class AccordionComponent implements OnInit {
  @Input() public showLabel = '';
  @Input() public showAllLabel = '';
  @Input() public showIcon = false;
  @Input() public showHeader = true;
  @Input() public showInfo = false;
  @Input() public hideLabel = '';
  @Input() public hideBorder = false;
  @Input() public expandDirection: 'up' | 'down' = 'down';
  @Input() public panelOpenState = false;

  public ngOnInit() {
    if (this.showIcon) {
      this.showInfo = true;
    }
  }
}
