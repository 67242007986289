<ng-container *ngIf="(documents && documents.length > 0) || (additionalDocuments && additionalDocuments.length > 0)">
  <div class="d-flex align-items-center needs-correction-title font-size-14 font-weight-bold mb-2">
    <mat-icon color="warn">notifications</mat-icon><span class="font-weight-bold">Needs Correction</span>
  </div>

  <ng-container *ngIf="documents && documents.length > 0">
    <p class="font-weight-bold">Please correct the following:</p>
    <ul class="mt-1">
      <ng-container *ngFor="let correction of documents">
        <li class="mb-2">
          <div class="font-weight-bold">{{ correction.documentName }}</div>
          <div *ngFor="let reason of correction.documentCorrections">
            {{ reason.correctionReason }}
            <span class="font-italic" *ngIf="reason.details"> - {{ reason.details }}</span>
          </div>
        </li>
      </ng-container>
    </ul>
  </ng-container>

  <ng-container *ngIf="additionalDocuments && additionalDocuments.length > 0">
    <p class="font-weight-bold">Please submit additional documents:</p>
    <ul class="mt-1">
      <li class="mb-2" *ngFor="let additionalDocument of additionalDocuments">
        <div class="font-weight-bold">
          {{ additionalDocument.type ? additionalDocument.type.name : additionalDocument.category.name }}
        </div>
        <div class="font-italic">{{ additionalDocument.additionalDetails }}</div>
      </li>
    </ul>
  </ng-container>
</ng-container>
