import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TransactionStatus } from '@dmv/common';
import { AuthenticationService } from '@dmv/shared/utils-authentication';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-dashboard-header',
  styleUrls: ['./dashboard-header.component.scss'],
  templateUrl: './dashboard-header.component.html',
})
export class DashboardHeaderComponent {
  @Input() public applicationMsg!: string;
  @Input() public appTitle!: string;
  @Input() public status!: TransactionStatus;
  @Input() public statusStyle!: string;
  @Input() public statusText!: string;
  @Input() public submittedOn!: string;
  @Input() public updatedAt!: string;
  @Input() public transactionId = '';

  public statusTypes: typeof TransactionStatus = TransactionStatus;
  public userAuthInfo$ = this._authenticationService.getAuthenticationInfo$();

  constructor(private readonly _authenticationService: AuthenticationService) {}
}
