<div class="survey-container" *ngIf="question">
  <section>
    <form class="form">
      <fieldset>
        <h2 class="survey-question" data-testid="survey-question">{{ question.question }}</h2>
        {{ question.warning }}
        <div class="warning-container" *ngIf="question.warning" [innerHTML]="question.warning" data-testid="warning-container"></div>
        <div
          class="additional-info-container"
          *ngIf="question.additionalInfo"
          [innerHTML]="question.additionalInfo"
          data-testid="additional-info-container"
        ></div>
        <dmv-toast
          *ngIf="question?.notes?.length > 0"
          [show]="displayUsedDocumentTip"
          [showIcon]="false"
          [showCloseButton]="false"
          (toastHidden)="displayUsedDocumentTip = false"
          type="info"
          heading="Please Note:"
        >
          <ul class="toast-content-list">
            <li *ngFor="let note of question.notes" [innerHTML]="note" data-testid="toast-content-list"></li>
          </ul>
        </dmv-toast>
        <div aria-role="radiogroup">
          <ng-container *ngFor="let answerPrompt of question.answerPrompts; index as i">
            <!-- TODO: we need to create a radio component for this it will live in form/radio and will be able to set card or default styles, lets copy how material does this -->
            <div
              class="input-group"
              *ngIf="i < maxInitialQuestions || (showMore === 1 && answerPrompt.metadata.idUpgradeEligible === true) || showMore === 2"
              data-testid="question-answerPrompt"
            >
              <input
                id="{{ answerPrompt.id + '_' + i }}"
                #radioInput
                [attr.aria-labelledby]="answerPrompt.value"
                [attr.data-cy]="question.id + answerPrompt.value"
                [checked]="answerPrompt.value === question.answers[0]"
                [value]="answerPrompt.value"
                type="radio"
                name="{{ answerPrompt.id }}"
              />

              <label
                class="input-group-label radio"
                #questionInput
                [ngClass]="{ selected: answerPrompt.value === question.answers[0] }"
                [attr.aria-checked]="answerPrompt.value === question.answers[0]"
                [attr.data-cy]="question.id + '-label'"
                (click)="handleClickSelect($event, answerPrompt, question)"
                (keydown.enter)="handleClickSelect($event, answerPrompt, question)"
                data-testid="radio-input"
                for="{{ answerPrompt.id + '_' + i }}"
                tabindex="0"
              >
                <div
                  [attr.data-cy]="question.id + '-prompt'"
                  [ngClass]="{ 'selected': answerPrompt.value === question.answers[0], 'main-text font-size-16': true }"
                >
                  {{ answerPrompt.prompt }}
                </div>
                <div
                  *ngIf="(displayAnswer && answerPrompt.additionalInfo) || !answerPrompt.metadata.idUpgradeEligible"
                  [class]="'survey-additional-info ' + answerPrompt?.promptStyles?.additionalInfo"
                  [ngClass]="{ selected: answerPrompt.value === question.answers[0] }"
                >
                  <span
                    [ngClass]="{ hidden: !(displayAnswer && answerPrompt.additionalInfo) }"
                    [innerHTML]="answerPrompt.additionalInfo"
                    data-testid="survey-additional-info"
                  ></span>
                  <span *ngIf="answerPrompt.metadata.idUpgradeEligible === false" data-testid="id-upgrade-eligible-indicator">
                    Not usable for Enhanced or REAL ID.
                  </span>
                </div>
                <div *ngIf="answerPrompt.value === question.answers[0] && answerPrompt.warning" [innerHTML]="answerPrompt.warning"></div>
              </label>
            </div>
          </ng-container>
        </div>
        <div class="show-more-container" *ngIf="showMore < 2 && question?.answerPrompts?.length > maxInitialQuestions">
          <button class="show-more-button" (click)="handleClickShowMore()" data-testid="show-more-button">
            <span class="main-text show-more-text">SHOW MORE OPTIONS</span>
            <mat-icon class="show-more-icon">expand_more</mat-icon>
          </button>
        </div>
      </fieldset>
    </form>
  </section>
</div>
