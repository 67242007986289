import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AnswerEvent, Question } from '@dmv/public/shared/http';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-survey',
  styleUrls: ['./survey.component.scss'],
  templateUrl: './survey.component.html',
})
export class SurveyComponent implements OnChanges, OnInit {
  @Input() public set displayAnswer(f: boolean) {
    this._displayAnswer = coerceBooleanProperty(f);
  }
  public get displayAnswer(): boolean {
    return this._displayAnswer;
  }

  @Input() public question: Question = {
    additionalInfo: '',
    answerPrompts: [],
    answers: [],
    id: '',
    name: '',
    notes: [],
    question: '',
    stepName: '',
    stepSectionFlowName: '',
    stepSectionName: '',
    totalPoints: 0,
    type: 'radio',
  };
  @Output() public readonly inputBlur: EventEmitter<AnswerEvent> = new EventEmitter<AnswerEvent>();

  public currentPoints = 0;

  private _displayAnswer = false;

  constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.currentPoints = this._calculatePointsForQuestion(changes.question.currentValue as Question);
    }
    this._changeDetectorRef.markForCheck();
  }

  public ngOnInit(): void {
    const isCheckboxType = this.question ? this.question.type === 'checkbox' || this.question.type === 'points_checkbox' : false;
    this.currentPoints =
      isCheckboxType && this.question.pointsThreshold
        ? this.question?.totalPoints || 0
        : !isCheckboxType
        ? this.question?.totalPoints || 0
        : this.question.answers.length;
  }

  public onAnswerSelection(event: AnswerEvent) {
    this.inputBlur.next(event);
    this._changeDetectorRef.markForCheck();
  }

  private _calculatePointsForQuestion(question: Question): number {
    if (
      question &&
      question.type === 'checkbox' &&
      !question.pointsThreshold &&
      (question.minPoints !== undefined || question.maxPoints !== undefined)
    ) {
      return question.answers.length;
    } else {
      return question?.totalPoints ?? 0;
    }
  }
}
