<dmv-toast [show]="showNotes" showIcon="false" type="info" heading="Please Note:">
  <ul class="toast-content-list">
    <li *ngFor="let text of uploadHelperTextList">{{ text }}</li>
    <li *ngIf="showInsuranceBullet">
      All names that appear on the vehicle registration must also be on the vehicle’s NY State Insurance Identification Card (FS-20, FS-21,
      FS-75)
    </li>
    <li *ngIf="coRegistrantDocPresent">
      You cannot add names to an existing vehicle registration (to add a name, you will need to register the vehicle with new plates)
    </li>
    <li *ngIf="isEnhanced">You must upload original documents and bring originals to the DMV</li>
  </ul>
  <p class="photo-helper-text">If you are taking a photo of the document with your phone, make sure to:</p>
  <ul class="toast-content-list">
    <li *ngFor="let text of photoUploadHelperTextList">
      {{ text }}
    </li>
  </ul>
</dmv-toast>
