import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DashboardAppointmentInformationModel } from '@dmv/common';
import { DateTime } from 'luxon';
import { FeatureFlagService } from '@libs/feature-flag';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-dashboard-appointment-information',
  styleUrls: ['./dashboard-appointment-information.component.scss'],
  templateUrl: './dashboard-appointment-information.component.html',
})
export class DashboardAppointmentInformationComponent implements OnInit, OnDestroy {
  @Input() public documentTypesPanelOpenState = true;
  @Input() public showSocialSecurityNotice = false;

  /**
   * The current timezone abbreviation for America/New_York.  One of: "EST" or "EDT". To be used with DatePIpe.
   */
  public timezoneAbbr?: string;

  public qrcodeLinkText = 'reservation QR code';
  public barcodeLinkText$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public barcodeLinkText = 'reservation bar code';
  private _appointmentInformation!: DashboardAppointmentInformationModel;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly _featureFlag: FeatureFlagService) {}

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  public ngOnInit(): void {
    this._featureFlag
      .isFeatureFlagEnabled$('public-applus-scheduling')
      .pipe(
        takeUntil(this.destroy$),
        map(enabled => {
          if (enabled) {
            this.barcodeLinkText$.next(this.barcodeLinkText);
          } else {
            this.barcodeLinkText$.next(this.qrcodeLinkText);
          }
        }),
      )
      .subscribe();
  }
  @Input() public appointmentMessage = '';
  public get appointmentInformation(): DashboardAppointmentInformationModel {
    return this._appointmentInformation;
  }
  @Input() public set appointmentInformation(value: DashboardAppointmentInformationModel) {
    this._appointmentInformation = value;
    if (value !== null) {
      this.timezoneAbbr = DateTime.fromISO(this._appointmentInformation.appointmentTime, { zone: 'America/New_York' }).toFormat('ZZZZ');
    }
  }
}
