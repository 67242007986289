import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { take, tap } from 'rxjs/operators';
import { SurveyDataService } from '../survey-data.service';

@Directive({})
export class SurveyQuestionBase implements AfterViewInit {
  public get maxInitialQuestions(): number {
    return 5;
  }

  @Input() public set displayAnswer(f: boolean) {
    this._displayAnswer = coerceBooleanProperty(f);
  }
  public get displayAnswer(): boolean {
    return this._displayAnswer;
  }

  @Input() public set focus(f: boolean) {
    const value = coerceBooleanProperty(f);
    if (value !== this._focus) {
      this._doInitialFocus();
    }
    this._focus = value;
  }
  public get focus(): boolean {
    return this._focus;
  }

  @Input() public set showMore(f: number) {
    this._showMore = f;
  }
  public get showMore(): number {
    return this._showMore;
  }

  @ViewChildren('questionInput') protected _activeQuestions?: QueryList<ElementRef>;

  public displayUsedDocumentTip = false;

  protected _displayAnswer = false;
  protected _focus = false;
  protected _showMore = 0;

  constructor(protected readonly _changeDetectorRef: ChangeDetectorRef, protected readonly _surveyDataService: SurveyDataService) {}

  public ngAfterViewInit() {
    this._focus && this._activeQuestions && this._doInitialFocus();
  }

  public handleClickShowMore(): void {
    this.showMore++;
    this._activeQuestions?.changes
      .pipe(
        take(1),
        tap((r: QueryList<ElementRef>) => {
          if (r.length > this.maxInitialQuestions) {
            const active: ElementRef[] = r.toArray();
            active[this.maxInitialQuestions] && active[this.maxInitialQuestions].nativeElement.focus();
          }
        }),
      )
      .subscribe();
  }

  protected _doInitialFocus(): void {
    if (this._activeQuestions) {
      const active: ElementRef[] = this._activeQuestions.toArray();
      active[0] && active[0].nativeElement.focus();
    }
  }
}
