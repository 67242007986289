import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Branch } from '@dmv/common';
import { Appointment, AppointmentReservation } from '@dmv/core';
import { TransactionType } from '@dmv/public/shared/http';
import { Observable } from 'rxjs';
import { AppointmentDetails } from './public-shared-scheduling-data-access.module';

@Injectable({
  providedIn: 'root',
})
export class SchedulingApiServiceV2 {
  public headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private readonly _httpClient: HttpClient, @Inject('env') private readonly _env) {}

  public getTimes(branchId: string, transactionType: TransactionType, date: string) {
    // TODO: Remove this placeholder once the feature flag is removed
  }

  //TODO - remove this when the feature flag is removed
  public submitAppointment(id: string, publicId: string, transactionType: TransactionType): Observable<Appointment> {
    return this._httpClient.post<Appointment>(
      `${this._env.apiUrl}/v3/scheduling/appointments/${publicId}/confirmations`,
      {
        id,
        transactionType: transactionType,
      },
      {
        headers: this.headers,
      },
    );
  }

  public submitApplusAppointment(
    appointmentDetails: AppointmentDetails,
    transactionType: TransactionType,
    transactionId: string,
  ): Observable<Appointment> {
    const bookingDateTime = `${appointmentDetails.date}T${appointmentDetails.timeSlot.startTime}`;

    return this._httpClient.post<Appointment>(
      `${this._env.apiUrl}/v3/scheduling/appointments/confirmation`,
      {
        bookingDateTime,
        locationId: appointmentDetails.branch.locationId,
        transactionId,
        transactionType: transactionType,
      },
      {
        headers: this.headers,
      },
    );
  }

  public reserveAppointment(branchId: string, date: string, transactionType: TransactionType, time: string) {
    return this._httpClient.post<AppointmentReservation>(
      `${this._env.apiUrl}/v3/scheduling/appointments/reservations`,
      {
        branchId,
        date,
        time,
        transactionType,
      },
      {
        headers: this.headers,
      },
    );
  }

  public getBranches(transactionType: TransactionType, sortBy: string, limit: number, address?: string, locationId?: number) {
    return this._httpClient.get<Branch[]>(
      `${this._env.apiUrl}/v3/scheduling/transaction-types/${transactionType}/branches/${sortBy}/${limit}`,
      {
        params: {
          address: address ? address : '',
          locationId: locationId ? locationId : '',
        },
      },
    );
  }

  public getDates(branchId: string, transactionType: TransactionType, transactionId: string) {
    return this._httpClient.get<string[]>(`${this._env.apiUrl}/v3/scheduling/${transactionType}/branches/${branchId}/dates`, {
      params: {
        transactionId: transactionId ? transactionId : '',
      },
    });
  }
}
