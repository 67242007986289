import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DisplayErrors } from '@dmv/public/shared/http';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-display-error',
  styleUrls: ['./display-error.component.scss'],
  templateUrl: './display-error.component.html',
})
export class DisplayErrorComponent {
  @Input() public displayErrors: DisplayErrors;
}
