import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { handleClickShowMore, hasIneligiblePromptCheck } from '@dmv/public/shared/utils';
import { AnswerPrompt, CheckboxAnswerEvent, DEFAULT_QUESTION, Question } from '@dmv/public/shared/http';
import { SurveyQuestionBase } from '../survey-question-base';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-checkbox-question',
  styleUrls: ['./checkbox-question.component.scss', '../../survey.component.scss'],
  templateUrl: './checkbox-question.component.html',
})
export class CheckboxQuestionComponent extends SurveyQuestionBase implements AfterViewInit {
  @Input() public currentPoints = 0;
  @Input() public set question(q: Question) {
    this._question = q;
    this.displayUsedDocumentTip = !!(q.notes && q.notes.length > 0);
  }
  public get question(): Question {
    return this._question;
  }
  @Output() public readonly checkboxBlur: EventEmitter<CheckboxAnswerEvent> = new EventEmitter<CheckboxAnswerEvent>();

  @ViewChildren(MatCheckbox) protected _activeCheckboxQuestions?: QueryList<MatCheckbox>;

  private _question: Question = { ...DEFAULT_QUESTION, type: 'checkbox' };

  public onCheckClick(answerPrompt: AnswerPrompt, question: Question): void {
    if (question.answers.includes(answerPrompt.value)) {
      this._handleDeselectAnswer(question, answerPrompt);
    } else {
      this._handleSelectAnswer(question, answerPrompt);
    }
  }

  public handleClickShowMore(): void {
    // increases the number of times that the show more button has been pressed
    // one time will show all the id upgrade eligible documents
    // two times will show all the documents regardless

    const showIneligible = hasIneligiblePromptCheck(this.question.answerPrompts);

    if (showIneligible && showIneligible > 1) {
      this.showMore++;
    } else {
      this.showMore = 2;
    }

    handleClickShowMore(this.showMore, this.question, this._activeCheckboxQuestions.changes, this.maxInitialQuestions);
  }

  private _handleDeselectAnswer(question: Question, answerPrompt: AnswerPrompt): void {
    this._updateQuestionAndEmit(question, answerPrompt, false);
  }

  private _handleSelectAnswer(question: Question, answerPrompt: AnswerPrompt): void {
    if (
      !this.question.pointsThreshold &&
      this.question.totalPoints &&
      this.question.totalPoints >= (question.maxPoints ?? 0) &&
      !this.question.answers.includes(answerPrompt.value)
    ) {
      this.checkboxBlur.emit({
        checked: false,
        currentPoints: this.currentPoints,
        error: `Only ${question.maxPoints} selections are allowed.`,
        points: question.minPoints,
        question: question,
        questionId: question.id,
        questionType: question.type,
        selection: answerPrompt,
      });
    } else {
      this._updateQuestionAndEmit(question, answerPrompt, true);
    }
  }

  private _updateQuestionAndEmit(question: Question, answerPrompt: AnswerPrompt, checked: boolean): void {
    this.currentPoints = this._surveyDataService.updatePoints(answerPrompt, this.currentPoints, question, checked);
    this.question.answers = this._surveyDataService.updateAnswers(this.question.answers, answerPrompt.value, 'checkbox', checked);
    this.question.totalPoints = this.currentPoints;
    this.checkboxBlur.emit({
      checked,
      currentPoints: this.currentPoints,
      points: question.minPoints,
      question: question,
      questionId: question.id,
      questionType: question.type,
      selection: answerPrompt,
    });
  }
}
