import { Component, Input } from '@angular/core';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'dmv-shared-feature-document-upload-helper-text',
  styleUrls: ['./document-upload-helper-text.component.scss'],
  templateUrl: './document-upload-helper-text.component.html',
})
export class DocumentUploadHelperTextComponent {
  @Input() public showInsuranceBullet: boolean;
  @Input() public coRegistrantDocPresent: boolean;
  // eslint-disable-next-line @angular-eslint/no-input-prefix
  @Input() public isEnhanced: boolean;
  @Input() public showNotes = true;

  public uploadHelperTextList = [
    'Accepted file types: .jpg, .jpeg, .pdf, .png',
    'Documents are legible and not blurry',
    'The full document is visible',
    'Upload front and back where applicable',
    'Documents should appear upright. You can rotate and re-upload if necessary',
  ];

  public photoUploadHelperTextList = [
    'Put the document on a flat surface and in a well lit area',
    'Clear the area of other unnecessary content',
    'Hold the phone level',
  ];
}
