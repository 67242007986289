import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ScrollUtils } from '@dmv/common';
import { OrderedSection, Section } from '@dmv/public/shared/http';
import { transformSections } from '../../utils/section-group.helper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-application-checklist',
  styleUrls: ['./application-checklist.component.scss'],
  templateUrl: './application-checklist.component.html',
})
export class ApplicationChecklistComponent {
  @Input() public title = '';
  @Input() public workflowInfo = '';
  @Output() public readonly restartClick: EventEmitter<Section> = new EventEmitter<Section>();
  @Output() public readonly sectionClick: EventEmitter<Section> = new EventEmitter<Section>();

  public focusEvent = 'dmv-focus-main-content-event';
  public transformedSections: OrderedSection[] = [];
  private _sections: Section[] = [];
  private readonly _skipElementString = 'main-skip-span';
  constructor(private readonly _scrollUtils: ScrollUtils) {}
  public get sections(): Section[] {
    return this._sections;
  }

  @Input() public set sections(sections: Section[]) {
    this._sections = sections;
    this.transformedSections = transformSections(sections);
  }

  public ngAfterViewInit() {
    this._scrollUtils.scrollToTop(this._skipElementString);
  }

  public onRestartClick(event: Section) {
    this.restartClick.emit(event);
  }

  public onSectionClick(event: Section) {
    this.sectionClick.emit(event);
  }
}
