import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppointmentStatus, Branch, TransactionStatus } from '@dmv/common';
import { BranchSearchParams, BranchSortType, WorkFlowType } from '@dmv/core';
import { TransactionType } from '@dmv/public/shared/http';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { BranchSortOption } from './models/branch.model';
import { DatesInfoModel } from './models/dates.models';
import {
  Appointment,
  AppointmentDetails,
  AppointmentReservation,
  contactPreferences,
  SchedulingCompletePayload,
  SchedulingDetail,
  SchedulingError,
  SchedulingInfo,
  SchedulingStep,
  schedulingSteps,
  schedulingWorkflow,
  SchedulingWorkFlowStep,
  TimeSlot,
} from './models/scheduling.models';
import { SchedulingApiServiceV2 } from './scheduling-api-v2.service';

enum ErrorMessage {
  DEFAULT = 'An error has occurred, please try again later.',
}

@Injectable({
  providedIn: 'root',
})
export class SchedulingDataService {
  public confirmedDetails = new BehaviorSubject<Appointment>(null);
  public currentWorkflowStep = new BehaviorSubject<SchedulingWorkFlowStep>(null);
  public disableContinueBtn = false;
  public errors$: Observable<SchedulingError[]>;
  public form = new BehaviorSubject<UntypedFormGroup>(null);
  public formSubmitted = new BehaviorSubject<boolean>(null);
  public reschedule = false;
  public reservedDetails = new BehaviorSubject<AppointmentReservation>(null);
  public schedulingComplete = new BehaviorSubject<SchedulingCompletePayload>(null);
  public selectedAppointmentDetails = new BehaviorSubject<AppointmentDetails>(null);
  public transaction = new BehaviorSubject<SchedulingInfo>(null);
  public transactionType = new BehaviorSubject<TransactionType>(null);
  public workflowOutlet = new BehaviorSubject<boolean>(true);
  public workflowSteps = new BehaviorSubject<SchedulingWorkFlowStep[]>([]);
  public featureFlagEnabled = false;
  public serviceReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public locationId: string = null;
  public dates: any[];
  public defaultSearchParams = {
    address: '',
    currentSortOption: {
      title: 'Closest DMV office to my location',
      type: 'proximity' as BranchSortType,
    },
    locationId: null,
    show: true,
  };
  public DEFAULT_LIMIT = 6;

  private readonly _currentStep = new BehaviorSubject<SchedulingStep>(schedulingSteps[0]);
  private readonly _steps = new BehaviorSubject<SchedulingStep[]>(schedulingSteps);

  private readonly _schedulingDetails = new BehaviorSubject<SchedulingDetail>(null);

  private readonly _branches = new BehaviorSubject<Branch[]>(null);
  private readonly _schedulingService!: SchedulingApiServiceV2;

  private readonly _branchSearchParams = new BehaviorSubject<BranchSearchParams>(this.defaultSearchParams);
  private readonly _errors = new BehaviorSubject(null);

  constructor(private readonly _router: Router, private readonly _schedulingApiServiceV2: SchedulingApiServiceV2) {
    this._schedulingService = this._schedulingApiServiceV2;
    this.serviceReady$.next(true);
    this.errors$ = this._errors.asObservable();
  }

  public completeSchedulingPortion(schedulingDetail: SchedulingDetail): void {
    this._setSchedulingDetails(schedulingDetail);
    this._markStepAsComplete('scheduling');

    const sameBranch = this.selectedAppointmentDetails.value?.branch?.publicId === this.reservedDetails.value?.branch?.publicId;
    const sameStartTime =
      new Date(`${this.selectedAppointmentDetails.value?.date}T${this.selectedAppointmentDetails.value?.timeSlot?.startTime}`).getTime() ===
      new Date(this.reservedDetails.value?.start).getTime();

    if (sameBranch && sameStartTime) {
      // TODO: https://nuvalence-dmv.atlassian.net/browse/DMVX-72
    }

    const nextStep = this.workflowSteps.value.find(step => step.id === 'reservation-summary');
    this.currentWorkflowStep.next(nextStep);
    this._setForm(nextStep.form);

    if (this.currentWorkflowStep?.value?.route && this.currentWorkflowStep?.value?.route?.length > 0) {
      this._currentStep.next({
        id: 'summary',
        route: null,
        showContinue: false,
        showPrevious: true,
        stepNumber: 3,
        title: 'Confirm your reservation details',
      });
    }
  }

  public getSchedulingInfo$(): Observable<{ steps: SchedulingStep[]; currentStep: SchedulingStep; details: SchedulingDetail }> {
    return combineLatest([this._steps, this._currentStep, this._schedulingDetails]).pipe(
      map(([steps, currentStep, details]) => {
        return {
          currentStep,
          details,
          steps,
        };
      }),
    );
  }

  public getBranchesInfo$(
    county: string,
    locationId?: number,
  ): Observable<{
    branchSearchParams: BranchSearchParams;
    branches: Branch[];
    branch: Branch;
  }> {
    const updatedSearchParams = { ...this.defaultSearchParams };
    if (county) {
      updatedSearchParams.address = `${county} COUNTY`;
    }
    if (locationId) {
      updatedSearchParams.locationId = locationId;
    }

    this._branchSearchParams.next(updatedSearchParams);

    return combineLatest([this._getBranches$(), this._branches, this._branchSearchParams, this._schedulingDetails]).pipe(
      map(([initial, branches, branchSearchParams, schedulingDetails]) => {
        return {
          branch: schedulingDetails?.branch,
          branchSearchParams,
          branches: branches || initial,
        };
      }),
    );
  }

  public getDatesInfo$(): Observable<DatesInfoModel> {
    return combineLatest([this._getDates$(this.transactionType.value, this.transaction.value.transactionId), this._schedulingDetails]).pipe(
      map(([dates, schedulingDetails]) => {
        this.dates = dates;

        return new DatesInfoModel({
          date: schedulingDetails?.date,
          dates,
        });
      }),
    );
  }

  public getReservationSummaryInfo$(): Observable<{
    appointmentConfirmed: boolean;
    pendingAppointmentDetails: AppointmentDetails;
    previousAppointmentDetails: AppointmentDetails;
    errors: SchedulingError[];
  }> {
    return combineLatest([this.selectedAppointmentDetails, this.errors$]).pipe(
      map(([appointmentDetails, errors]) => {
        let previousAppointmentDetails;
        if (this.transaction.value?.appointmentTime) {
          previousAppointmentDetails = this._getAppointmentDetailsFromTransaction();
        }

        return {
          // TODO - how to handle this in OP vs real id - will use the parameter transactionType to determine how the appointment is confirmed
          appointmentConfirmed: this.transaction?.value?.status === TransactionStatus.FULFILLED,
          errors,
          pendingAppointmentDetails: appointmentDetails,
          previousAppointmentDetails,
        };
      }),
    );
  }

  public getTimesInfo$(): Observable<{ timeSlots: TimeSlot[]; date: string; timeSlot: TimeSlot }> {
    return combineLatest([this._getTimes$(), this._schedulingDetails]).pipe(
      map(([timeSlots, scheduleDetails]) => {
        return {
          date: scheduleDetails?.date,
          timeSlot: scheduleDetails?.timeSlot && scheduleDetails.timeSlot,
          timeSlots,
        };
      }),
    );
  }

  public handleSchedulingWorkflow(appointmentStatus: AppointmentStatus): void {
    this._setWorkflowOutlet(true);
    this._setWorkFlowSteps(schedulingWorkflow);
    this.currentWorkflowStep.next(schedulingWorkflow[0]);
    if (appointmentStatus === AppointmentStatus.SCHEDULED || appointmentStatus === AppointmentStatus.RESCHEDULED) {
      this.reschedule = true;
      const index = this.workflowSteps.value.findIndex((step: SchedulingWorkFlowStep) => step.id === 'reservation-summary');
      this.workflowSteps.value[index].nextButtonText = 'Reschedule';
    }
  }

  public hasAddress(): boolean {
    return this._branchSearchParams.value?.address?.length > 0;
  }

  public onReservationSummaryContinue(func: EventEmitter<void>): void {
    this._setContinueBtnDisabled(true); // Disable the continue button as an interim fix for long qMatic request times
    this._confirmAppointment(this.transaction.value.id, this.transactionType.value)
      .pipe(
        take(1),
        tap(() => {
          func.emit();
          this._router.navigateByUrl(`home/${this._getFlowSpecificPath()}/dashboard`).finally(() => {
            this.resetScheduling();
          });
        }),
        catchError((_err: HttpErrorResponse) => {
          this._setErrors([{ message: _err?.error?.message || ErrorMessage.DEFAULT, errorCode: _err?.error?.errorCode }]);
          this._setContinueBtnDisabled(false);

          // Handles the case where the user has selected a time slot that has already been booked
          if (_err.error?.errorCode === 'TIME_SLOT_BOOKED') {
            // Get the most up to date time slots
            this.getDatesInfo$()
              .pipe(
                take(1),
                map(() => {
                  // This will nav the user back to the time step
                  this._currentStep.next(this._steps.value.find(step => step.id === 'time'));
                  this._schedulingDetails.next({
                    branch: this._schedulingDetails.value.branch,
                    date: this._schedulingDetails.value.date,
                    timeSlot: null,
                  });
                }),
              )
              .subscribe();
          }

          return of(true);
        }),
        tap(() => {
          this._setFormSubmitted(false);
          this._setContinueBtnDisabled(false);
        }),
      )
      .subscribe();
  }

  public setDefaultLocation(location: Branch) {
    this._currentStep.next(this._steps.value.find(step => step.id === 'date'));
    this._setBranch(location);
  }

  public continueToDateStep() {
    if (this.getCurrentStep().id !== 'date') {
      this._currentStep.next(this._steps.value.find(step => step.id === 'date'));
    }
  }

  public resetScheduling(): void {
    this._disableContinueOnStep('location');
    this._disableContinueOnStep('date');
    this._disableContinueOnStep('time');
    this._currentStep.next(this._steps.value.find(step => step.id === 'location'));
    this.locationId = null;
    this._schedulingDetails.next(null);
    this._branchSearchParams.next({
      address: '',
      currentSortOption: {
        title: 'Appointment availability',
        type: 'availability',
      },
      show: true,
    });
  }

  public updateBranchSort(branchSortOption: BranchSortOption): Observable<void> {
    return this._setBranchSearchParams({
      address: this._branchSearchParams.value.address,
      currentSortOption: branchSortOption,
    });
  }

  public updateBranchAddress(): Observable<void> {
    return this._setBranchSearchParams({
      ...this._branchSearchParams.value,
    });
  }
  public nextStep(location: 'previous' | 'continue'): void {
    this._setErrors(null);
    const currentStepId = this._currentStep?.value?.id;
    const branch = this._schedulingDetails.value?.branch;
    const date = this._schedulingDetails.value?.date;
    const timeSlot = this._schedulingDetails.value?.timeSlot;

    let nextStep = null;

    switch (currentStepId) {
      case 'location':
        if (location === 'previous') {
          // return to parent flow previous step if any
          return;
        }
        if (location === 'continue' && branch) {
          this._enableContinueOnStep(currentStepId);
          this._enablePreviousOnStep('date');
          nextStep = this._steps.value.find(step => step.id === 'date');
        } else {
          nextStep = this._steps.value.find(step => step.id === currentStepId);
        }
        break;
      case 'date':
        if (location === 'previous') {
          nextStep = this._steps.value.find(step => step.id === 'location');
        }
        if (location === 'continue' && date) {
          this._enableContinueOnStep(currentStepId);
          this._enablePreviousOnStep('time');
          nextStep = this._steps.value.find(step => step.id === 'time');
        }
        break;
      case 'time':
        if (location === 'previous') {
          nextStep = this._steps.value.find(step => step.id === 'date');
        }
        if (location === 'continue' && timeSlot) {
          this._enableContinueOnStep(currentStepId);
          if (branch && date && timeSlot) {
            this._setSchedulingComplete();
          }

          return;
        }
        break;
      case 'summary':
        if (location === 'previous') {
          nextStep = this._steps.value.find(step => step.id === 'time');
        }
        break;
      default:
        break;
    }

    this._currentStep.next(nextStep);
  }

  public navigateBackFromWorkflow(): void {
    const lastStep = this._steps.value.find(step => step.id === 'time');
    this._currentStep.next(lastStep);
    this._router.navigateByUrl(`home/${this._getFlowSpecificPath()}/scheduling/${lastStep.route.join('/')}`);
  }

  public setUpgradeInfoForDashboard() {
    this._setWorkflowOutlet(false);
  }

  public updateBranch(branch: Branch): void {
    this._setBranch(branch);
    this._disableContinueOnStep('date');
    this._disableContinueOnStep('time');
    this.nextStep('continue');
  }

  public updateDate(date: string): void {
    this._setDate(date);
    this._disableContinueOnStep('time');
    this.nextStep('continue');
  }

  public updateTransactionType(transactionType: TransactionType): void {
    this.transactionType.next(transactionType);
  }
  public updateTime(timeSlot: TimeSlot): void {
    this._setTime(timeSlot);
    this.nextStep('continue');
  }

  public getCurrentStep() {
    return this._currentStep.value;
  }

  public setDefaultSearchLimit(limit: number) {
    this.DEFAULT_LIMIT = limit;
  }

  public mapTransactionToSchedulingInfo(transaction: any): SchedulingInfo {
    return {
      appointmentStatus: transaction.appointmentStatus,
      appointmentTime: transaction.appointmentTime,
      branchAddress: transaction.branchAddress,
      branchCity: transaction.branchCity,
      cancelByDate: transaction.cancelByDate,
      contactPreferenceType: transaction.contactPreference ? transaction.contactPreference : null,
      firstName: transaction.firstName,
      id: transaction.id,
      status: transaction.status,
      transactionId: transaction.transactionId,
    };
  }

  private _confirmAppointment(id, transactionType): Observable<Appointment> {
    return this._schedulingService
      .submitApplusAppointment(this.selectedAppointmentDetails.value, transactionType, this.transaction.value.transactionId || null)
      .pipe(
        map((appointmentConfirmationDetails: Appointment) => {
          this._setAppointmentConfirmation(appointmentConfirmationDetails);

          return appointmentConfirmationDetails;
        }),
      );
  }

  private _getAppointmentDetailsFromTransaction(): AppointmentDetails {
    const transaction = this.transaction.value;
    let contactPreferenceLabel;
    let contactPreferenceIcon;
    switch (transaction.contactPreferenceType) {
      case 'phone':
        contactPreferenceLabel = 'Phone Call';
        contactPreferenceIcon = 'fas fa-phone-alt';
        break;
      case 'sms':
        contactPreferenceLabel = 'Text / SMS';
        contactPreferenceIcon = 'far fa-comment-alt';
        break;
      case 'email':
        contactPreferenceLabel = 'Email';
        contactPreferenceIcon = 'far fa-envelope';
        break;
    }

    return {
      branch: {
        addressCity: transaction.branchCity,
        addressLine1: transaction.branchAddress,
      },
      contactPreference: {
        icon: contactPreferenceIcon,
        label: contactPreferenceLabel,
      },
      date: null,
      previousStartTime: transaction.appointmentTime,
      timeSlot: null,
    } as AppointmentDetails;
  }

  private _getFlowSpecificPath(): string {
    let path = '';
    switch (this.transactionType.value) {
      case TransactionType.ID_UPGRADE:
        path = 'real-id-edl-upgrade';
        break;
      case TransactionType.PERMIT:
        path = 'original-permit';
        break;
      case TransactionType.LICENSE_RECIPROCITY:
        path = 'exchange-license';
        break;
      case TransactionType.ORIGINAL_NON_DRIVER_ID:
        path = 'original-non-driver-id';
        break;
      case TransactionType.ORIGINAL_REGISTRATION:
        path = `register-vehicle/${this.transaction.value.transactionId}`;
        break;
    }

    return path;
  }

  private _reserveAppointment$(): Observable<AppointmentReservation> {
    const { branch, date, timeSlot } = this.selectedAppointmentDetails.value;

    return this._schedulingService.reserveAppointment(branch.publicId, date, this.transactionType.value, timeSlot.startTime).pipe(
      map((appointmentReservation: AppointmentReservation) => {
        this._setAppointmentReservation(appointmentReservation);

        return appointmentReservation;
      }),
    );
  }

  private _setAppointmentConfirmation(appointment: Appointment): void {
    this.confirmedDetails.next(appointment);
    const appointmentStatus =
      this.transaction.value.appointmentStatus === AppointmentStatus.SCHEDULED ||
      this.transaction.value.appointmentStatus === AppointmentStatus.RESCHEDULED
        ? AppointmentStatus.RESCHEDULED
        : AppointmentStatus.SCHEDULED;
    this.transaction.next({
      ...this.transaction.value,
      appointmentStatus: appointmentStatus,
    });
  }

  private _setBranchSearchParams(branchSearchParams: BranchSearchParams): Observable<void> {
    const {
      currentSortOption: { type },
      address,
    } = branchSearchParams;

    if (type === 'availability') {
      this._branchSearchParams.next({
        ...branchSearchParams,
        show: true,
      });
    } else if (type === 'proximity') {
      this._branchSearchParams.next({
        ...branchSearchParams,
        show: false,
      });
    }

    return this._getBranches$().pipe(
      tap(() => {
        if (type === 'proximity') {
          this._branchSearchParams.next({
            ...branchSearchParams,
            show: true,
          });
        }
      }),
    ) as Observable<void>;
  }

  private _setContinueBtnDisabled(disabled: boolean): void {
    this.disableContinueBtn = disabled;
  }

  private _setFormSubmitted(submitted: boolean): void {
    this.formSubmitted.next(submitted);
  }

  private _setAppointmentDetails(appointmentDetails: AppointmentDetails): void {
    this.selectedAppointmentDetails.next(appointmentDetails);
  }

  private _setAppointmentReservation(appointmentReservation) {
    this.reservedDetails.next(appointmentReservation);
  }

  private _setBranch(branch: Branch): void {
    this.locationId = branch.publicId || branch.locationId;
    this._schedulingDetails.next({
      branch,
      date: null,
      timeSlot: null,
    });
  }

  private _setForm(form: UntypedFormGroup): void {
    this.form.next(form);
  }

  private _setSchedulingDetails(schedulingDetails: SchedulingDetail): void {
    const preference = this.selectedAppointmentDetails.value?.contactPreference
      ? this.selectedAppointmentDetails.value.contactPreference
      : this.transaction.value?.contactPreferenceType
      ? contactPreferences.find(cp => cp.type === this.transaction.value.contactPreferenceType)
      : null;
    this._setAppointmentDetails({
      ...schedulingDetails,
      contactPreference: preference,
    });
  }

  private _sortBranches(branches: Branch[]): Branch[] {
    switch (this._branchSearchParams.value?.currentSortOption?.type) {
      case 'proximity':
        return branches.sort((branchA: Branch, branchB: Branch) => {
          if (branchA.distanceMiles < branchB.distanceMiles) {
            return -1;
          }
          if (branchA.distanceMiles > branchB.distanceMiles) {
            return 1;
          }

          return 0;
        });
      case 'availability':
      default:
        return branches.sort((branchA: Branch, branchB: Branch) => {
          const aa = new Date(branchA.earliestAvailableDate);
          const bb = new Date(branchB.earliestAvailableDate);

          if (aa !== bb) {
            if (aa > bb) {
              return 1;
            }
            if (aa < bb) {
              return -1;
            }
          }

          return 0;
        });
    }
  }

  private _disableContinueOnStep(stepId: 'location' | 'date' | 'time'): void {
    const updatedSteps = this._steps.value.map(step => {
      if (step.id === stepId) {
        return {
          ...step,
          showContinue: false,
        };
      }

      return step;
    });
    this._steps.next(updatedSteps);
  }

  private _enableContinueOnStep(stepId: 'location' | 'date' | 'time'): void {
    const updatedSteps = this._steps.value.map(step => {
      if (step.id === stepId) {
        return {
          ...step,
          showContinue: true,
        };
      }

      return step;
    });
    this._steps.next(updatedSteps);
  }

  private _enablePreviousOnStep(stepId: 'location' | 'date' | 'time'): void {
    const updatedSteps = this._steps.value.map(step => {
      if (step.id === stepId) {
        return {
          ...step,
          showPrevious: true,
        };
      }

      return step;
    });
    this._steps.next(updatedSteps);
  }

  private _getBranches$(): Observable<Branch[]> {
    const {
      currentSortOption: { type },
      address,
      locationId,
    } = this._branchSearchParams.value;

    return this._schedulingService.getBranches(this.transactionType.value, type, this.DEFAULT_LIMIT, address, locationId).pipe(
      map((branches: Branch[]) => {
        const sorted = this._sortBranches(branches);
        this._setBranches(sorted);

        return sorted;
      }),
    );
  }

  private _getDates$(transactionType: TransactionType, transactionId: string): Observable<string[]> {
    return this._schedulingService.getDates(this.locationId, transactionType, transactionId);
  }

  private addMinutes(time, minsToAdd) {
    function D(J) {
      return (J < 10 ? '0' : '') + J;
    }
    const piece = time.split(':');
    const mins = piece[0] * 60 + +piece[1] + +minsToAdd;

    return `${D(((mins % (24 * 60)) / 60) | 0)}:${D(mins % 60)}:00`;
  }

  private _getTimes$(): Observable<TimeSlot[]> {
    let dates: any;
    const newDates = this._schedulingDetails?.value.date
      ? this.dates.find(date => date.date === this._schedulingDetails?.value.date)?.times
      : null;

    if (newDates.length > 0) {
      dates = of(
        newDates.map(date => ({
          endTime: this.addMinutes(date, 60),
          startTime: date,
        })),
      );
    }

    return this._schedulingDetails?.value ? dates : EMPTY;
  }

  private _markStepAsComplete(stepId: WorkFlowType): void {
    const updatedSteps = this.workflowSteps.value.map<SchedulingWorkFlowStep>((step: SchedulingWorkFlowStep) => {
      if (step.id === stepId) {
        return {
          ...step,
          status: 'completed',
        };
      }

      return step;
    });
    this._setWorkFlowSteps(updatedSteps);
  }

  private _setBranches(branches: Branch[]): void {
    this._branches.next(branches);
  }

  private _setDate(date: string): void {
    this.locationId = this._schedulingDetails.value.branch.publicId || this._schedulingDetails.value.branch.locationId;
    this._schedulingDetails.next({
      branch: this._schedulingDetails.value.branch,
      date,
      timeSlot: null,
    });
  }

  private _setErrors(errors: SchedulingError[]): void {
    this._errors.next(errors);
  }

  private _setSchedulingComplete(): void {
    this.schedulingComplete.next({
      complete: true,
      schedulingDetails: this._schedulingDetails.value,
    });
    this.schedulingComplete.next(null);
  }

  private _setTime(timeSlot: TimeSlot): void {
    this.locationId = this._schedulingDetails.value.branch.publicId || this._schedulingDetails.value.branch.locationId;
    this._schedulingDetails.next({
      branch: this._schedulingDetails.value.branch,
      date: this._schedulingDetails.value.date,
      timeSlot,
    });
  }

  private _setWorkflowOutlet(workflowOutlet: boolean): void {
    this.workflowOutlet.next(workflowOutlet);
  }

  private _setWorkFlowSteps(_steps: SchedulingWorkFlowStep[]): void {
    this.workflowSteps.next(_steps);
  }
}
