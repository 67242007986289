import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnswerEvent, AnswerPrompt, DEFAULT_QUESTION, Question } from '@dmv/public/shared/http';
import { handleClickShowMore, hasIneligiblePromptCheck } from '@dmv/public/shared/utils';
import { SurveyQuestionBase } from '../survey-question-base';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-radio-question',
  styleUrls: ['./radio-question.component.scss', '../../survey.component.scss'],
  templateUrl: './radio-question.component.html',
})
export class RadioQuestionComponent extends SurveyQuestionBase implements AfterViewInit, OnInit {
  @Input() public currentPoints = 0;
  @Output() public readonly radioBlur: EventEmitter<AnswerEvent> = new EventEmitter<AnswerEvent>();

  private _question: Question = { ...DEFAULT_QUESTION, type: 'checkbox' };
  public get question(): Question {
    return this._question;
  }
  @Input() public set question(q: Question) {
    this._question = q;
    this.displayUsedDocumentTip = !!(q.notes && q.notes.length > 0);
  }

  public ngOnInit() {
    this.displayUsedDocumentTip = this.question.notes && this.question.notes.length > 0 ? true : false;
    if (this.question.hasPreviouslyUsedUpload && this.question.answers.length > 0) {
      const answerPrompt: AnswerPrompt | undefined = this.question.answerPrompts.find(answer => answer.id === this.question.answers[0]);
      answerPrompt &&
        this.radioBlur.emit({ question: this.question, questionId: this.question.id, questionType: 'radio', selection: answerPrompt });
    }
  }

  public handleClickSelect(event: Event, answerPrompt: AnswerPrompt, _question: Question): void {
    event.stopPropagation();
    this.currentPoints = this._surveyDataService.updatePoints(answerPrompt, this.currentPoints, this.question);
    this.question.totalPoints = this.currentPoints;
    this.question.answers = this._surveyDataService.updateAnswers(this.question.answers, answerPrompt.value, this.question.type);
    this.radioBlur.emit({
      currentPoints: this.currentPoints,
      points: this.question.minPoints,
      question: this.question,
      questionId: this.question.id,
      questionType: this.question.type,
      selection: answerPrompt,
    });
  }

  public handleClickShowMore(): void {
    // increases the number of times that the show more button has been pressed
    // one time will show all the id upgrade eligible documents
    // two times will show all the documents regardless

    const showIneligible = hasIneligiblePromptCheck(this.question.answerPrompts);

    if (showIneligible && showIneligible > 1) {
      this.showMore++;
    } else {
      this.showMore = 2;
    }

    handleClickShowMore(this.showMore, this.question, this._activeQuestions.changes, this.maxInitialQuestions);
  }
}
