import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DashboardConfiguration } from '@dmv/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-dashboard-completed-steps',
  styleUrls: ['./dashboard-completed-steps.component.scss'],
  templateUrl: './dashboard-completed-steps.component.html',
})
export class DashboardCompletedStepsComponent {
  @Input() public currentConfiguration!: DashboardConfiguration;
}
