/* eslint-disable @angular-eslint/use-component-selector */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AnswerEvent, CheckboxAnswerEvent, Question, SurveyEvent } from '@dmv/public/shared/http';
import { EMPTY, Observable, Subject } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '',
})
export class SurveyWrapperBaseComponent implements OnChanges {
  @Input() public questionIndex = 0;
  @Input() public questions: Question[] = [];
  @Input() public showPreviousButton = true;
  @Input() public set displayAnswer(f: boolean) {
    this._displayAnswer = coerceBooleanProperty(f);
  }
  public get displayAnswer(): boolean {
    return this._displayAnswer;
  }
  @Output() public readonly inputBlur: EventEmitter<AnswerEvent> = new EventEmitter<AnswerEvent>();
  @Output() public readonly surveyComplete: EventEmitter<SurveyEvent> = new EventEmitter<SurveyEvent>();
  @Output() public readonly previousClick = new EventEmitter();
  @Output() public readonly surveyIndexUpdated = new EventEmitter<SurveyEvent>();

  public bottomError = '';
  public error = '';
  public focusMonitor$: Observable<boolean> = EMPTY;

  private readonly _focusMonitor: Subject<boolean> = new Subject<boolean>();
  private _displayAnswer = false;
  constructor(protected readonly _changeDetectorRef: ChangeDetectorRef) {
    this.focusMonitor$ = this._focusMonitor.asObservable();
  }

  public ngOnChanges(_changes: SimpleChanges): void {
    this._changeDetectorRef.markForCheck();
  }

  public onAnswerSelection(event: AnswerEvent) {
    this.inputBlur.emit({ ...event, questionIndex: this.questionIndex });
    this.error = '';
  }

  public onInputBlur(event: AnswerEvent) {
    // TODO This event will probably be necessary for certain actions.
    if (event && (event as CheckboxAnswerEvent).error !== undefined) {
      this.bottomError = (event as CheckboxAnswerEvent).error ?? ''; // Nullish coalesce for type string | undefined
    }
    this.error = '';
    this.inputBlur.emit({ ...event, questionIndex: this.questionIndex });
    this._changeDetectorRef.markForCheck();
  }

  public onContinueClick() {
    this.error = '';
    if (this._validate(this.questions[this.questionIndex])) {
      if (this.questionIndex + 1 < this.questions.length) {
        this.questionIndex++;
        this.surveyIndexUpdated.emit({ questionIndex: this.questionIndex, questions: this.questions });
      } else {
        this.surveyComplete.emit({ questionIndex: this.questionIndex, questions: this.questions });
      }
    }
  }

  public onPreviousClick() {
    if (this.questionIndex > 0) {
      this.previousClick.emit();
      this.questionIndex--;
      this.surveyIndexUpdated.emit({ questionIndex: this.questionIndex, questions: this.questions });
    } else {
      this.surveyComplete.emit({ questionIndex: this.questionIndex, questions: this.questions });
    }
  }

  protected _validate(question: Question): boolean {
    let isValid = true;
    switch (question.type) {
      case 'checkbox':
        // eslint-disable-next-line no-case-declarations
        const totalPoints = question.totalPoints || 0;

        if (question.pointsThreshold && totalPoints < question.pointsThreshold) {
          const name = question.name.replace(/-/g, ' ');
          this.error = `You need a minimum of ${question.pointsThreshold} name points. Please choose additional documents for ${name}.`;
          isValid = false;
        } else {
          if (question.minPoints && totalPoints < question.minPoints) {
            this.error = `Please choose ${question.minPoints - totalPoints} more document(s) to continue`;
            isValid = false;
          }
        }
        break;
      case 'radio':
        if (question.totalPoints != null && question.totalPoints < 1) {
          this.error = 'Please make a selection';
          isValid = false;
        }
        break;
      default:
        break;
    }
    this._focusMonitor.next(!isValid);

    return isValid;
  }
}
