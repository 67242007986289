import { DateTime } from 'luxon';

const DATE_FORMAT = 'yyyy-LL-dd';

export interface DatesInfoSchema {
  date?: string;
  dates?: string[];
}

export interface ApplusDatesSchema extends DatesInfoSchema {
  date?: string;
  dates?: any[];
}

export class DatesInfoModel {
  public date?: DatesInfoSchema['date'];
  public dates?: DatesInfoSchema['dates'] = [];
  public maxDate?: Date;
  public minDate?: Date;
  public selectedDate?: Date;
  public featureFlagEnabled?: boolean;

  constructor(datesInfoSchema?: any) {
    if (datesInfoSchema) {
      this.fromSchemaV3(datesInfoSchema);
    }
  }

  public availableDatesFilter?(d: Date): boolean;

  public fromSchemaV3(datesInfoSchema: ApplusDatesSchema): void {
    if (datesInfoSchema.dates && datesInfoSchema.dates.length > 0) {
      this.availableDatesFilter = (d: Date): boolean => {
        const dateString = DateTime.fromJSDate(d).toFormat(DATE_FORMAT);

        return datesInfoSchema.dates.some(date => {
          return date.date === dateString;
        });
      };

      this.date = datesInfoSchema.date;
      this.dates = datesInfoSchema.dates;
      this.maxDate = DateTime.fromFormat(datesInfoSchema.dates[datesInfoSchema.dates.length - 1].date, DATE_FORMAT).toJSDate();

      this.minDate = DateTime.fromFormat(datesInfoSchema.dates[0].date, DATE_FORMAT).toJSDate();

      this.selectedDate = datesInfoSchema.date ? DateTime.fromFormat(datesInfoSchema.date, DATE_FORMAT).toJSDate() : null;
    }
  }
}
