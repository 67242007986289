<section *ngIf="question">
  <div class="points" *ngIf="question.pointsThreshold">
    <div>
      <span class="font-weight-bold"> You currently have: </span>
      <span class="mb-6 font-weight-bold" [ngClass]="currentPoints >= question.pointsThreshold ? 'success' : 'danger'" aria-live="polite">
        {{ currentPoints }} / {{ question.pointsThreshold }} Proof Points<span *ngIf="currentPoints >= question.pointsThreshold">
          - Completed</span
        >
      </span>
    </div>
  </div>

  <form class="form">
    <fieldset>
      <h2 class="survey-question" data-testid="survey-question">{{ question.question }}</h2>

      <p [innerHTML]="question.additionalInfo" data-testid="additional-info-container"></p>

      <dmv-toast
        *ngIf="question.notes && question.notes.length > 0"
        [show]="displayUsedDocumentTip"
        [showCloseButton]="false"
        [showIcon]="false"
        (toastHidden)="displayUsedDocumentTip = false"
        type="info"
        heading="Please Note:"
      >
        <ul class="toast-content-list">
          <li *ngFor="let note of question.notes" [innerHTML]="note"></li>
        </ul>
      </dmv-toast>

      <div *ngFor="let answerPrompt of question.answerPrompts; index as i">
        <div
          class="input-group"
          *ngIf="i < maxInitialQuestions || (showMore === 1 && answerPrompt.metadata.idUpgradeEligible === true) || showMore === 2"
          data-testid="question-answerPrompt"
        >
          <mat-checkbox
            class="input-group-checkbox"
            id="{{ answerPrompt.id }}"
            #questionInput
            [aria-label]="answerPrompt.prompt"
            [name]="answerPrompt.value"
            [value]="answerPrompt.value"
            [checked]="question.answers.includes(answerPrompt.value)"
            (click)="onCheckClick(answerPrompt, question)"
            color="primary"
          ></mat-checkbox>
          <label
            class="input-group-label"
            #activeQuestion
            [ngClass]="{ selected: question.answers.includes(answerPrompt.value) }"
            (click)="onCheckClick(answerPrompt, question)"
            for="{{ answerPrompt.id }}"
            data-testid="checkbox-input-label"
          >
            <div class="input-container">
              <div class="title">
                <div class="main-text font-size-16" [ngClass]="answerPrompt?.promptStyles?.prompt">
                  {{ answerPrompt.prompt }}
                </div>
                <div
                  class="sub-text"
                  *ngIf="answerPrompt.additionalInfo || !answerPrompt.metadata.idUpgradeEligible"
                  [ngClass]="answerPrompt?.promptStyles?.additionalInfo"
                >
                  <span [ngClass]="{ hidden: !answerPrompt.additionalInfo }" [innerHTML]="answerPrompt.additionalInfo"></span>
                  <div
                    *ngIf="answerPrompt.metadata.idUpgradeEligible === false && answerPrompt.id !== 'NO-SECOND-PROOF'"
                    data-testid="id-upgrade-eligible-indicator"
                  >
                    Not usable for Enhanced or REAL ID.
                  </div>
                </div>
              </div>
              <div class="sub-point" *ngIf="question.pointsThreshold && answerPrompt.metadata.points">
                {{ answerPrompt.metadata.points }} {{ answerPrompt.metadata.points < 2 ? 'pt' : 'pts' }}
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="show-more-container" *ngIf="showMore < 2 && question?.answerPrompts?.length > maxInitialQuestions">
        <button class="show-more-button" (click)="handleClickShowMore()" data-testid="show-more-button">
          <span class="main-text show-more-text">SHOW MORE OPTIONS</span>
          <mat-icon class="show-more-icon">expand_more</mat-icon>
        </button>
      </div>
    </fieldset>
  </form>
</section>
