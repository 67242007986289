/* eslint-disable sort-keys */
import { Injectable } from '@angular/core';
import { TransactionStatus } from '@dmv/common';
import { DocumentStatus } from '@dmv/public/shared/http';
import { Observable, of } from 'rxjs';
import { GetAdditionalInformationResponse } from '../models/document-reason';

export const ADDITIONAL_INFORMATION_RESPONSE: GetAdditionalInformationResponse = {
  additionalDocuments: [
    {
      additionalDetails: 'some details',
      category: { id: 11, name: 'Doc Category' },
      id: 2,
      type: { id: 11, name: 'DocType' },
    },
  ],
  documentCorrections: [
    {
      id: 1,
      correctionReason: { id: 11, name: 'Missing Documents' },
      details: 'Please submit additional documents',
      documentName: 'ID Card',
    },
  ],
  transactionDocumentation: {
    id: 'ID2',
    documents: [
      {
        coRegistrant: false,
        documentType: {
          additionalInfo: 'Should be valid id',
          birthProof: false,
          businessProof: false,
          childDocumentTypes: null,
          customPreview: false,
          customImage: 'http://image',
          foreign: false,
          hasBack: false,
          id: 1107,
          instructions: 'upload both sides',
          link: 'http://imagelink',
          metadata: { points: 1 },
          name: 'State ID',
          sequentialUpload: false,
          shortName: 'State ID',
          nameProof: false,
          popularity: 117,
          residencyProof: false,
          verification: false,
          required: false,
          coRegistrant: false,
        },
        id: 11,
        location: 'location',
        status: DocumentStatus.CORRECT,
        displayDocFraudToolTip: true,
      },
    ],
    messages: [], // Actually a Message[] but this class can't reference an mvr defined file.
    status: TransactionStatus.DRAFT,
    transactionId: '',
  },
};

@Injectable()
export class DocumentsApiMockService {
  public getAdditionInformation(_transactionType: string): Observable<GetAdditionalInformationResponse> {
    return of(ADDITIONAL_INFORMATION_RESPONSE);
  }
}
