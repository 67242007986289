import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth, Tokens } from '@okta/okta-auth-js';
// @ts-ignore
// Typings are not available. Reference https://github.com/okta/okta-signin-widget/issues/2385
import OktaSignIn from '@okta/okta-signin-widget';
import { AUTHENTICATION_CONFIGURATION, AuthenticationConfiguration } from '../authentication.type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-okta-login',
  styleUrls: ['./okta-login.component.scss'],
  templateUrl: './okta-login.component.html',
})
export class OktaLoginComponent implements OnInit {
  private readonly _widget = new OktaSignIn({
    authParams: {
      pkce: true,
    },
    baseUrl: this._authenticationConfiguration.okta.issuer,
    clientId: this._authenticationConfiguration.okta.clientId,
    el: '#okta-signin-container',
    redirectUri: this._authenticationConfiguration.okta.redirectUri,
    scopes: ['openid', 'profile', 'email'],
  });

  constructor(
    @Inject(AUTHENTICATION_CONFIGURATION)
    private readonly _authenticationConfiguration: AuthenticationConfiguration,
    // TODO: Check if we can get the configuration from oktaAuth in the
    // new version and remove the above config injection
    @Inject(OKTA_AUTH) private readonly _oktaAuth: OktaAuth,
    private readonly _router: Router,
  ) {
    // Show the widget when prompted, otherwise remove it from the DOM.
    this._router.events.forEach(event => {
      if (event instanceof NavigationStart) {
        switch (event.url) {
          case '/login':
            break;
          case '/protected':
            break;
          default:
            this._widget.remove();
            break;
        }
      }
    });
  }

  public async ngOnInit() {
    const originalUri = this._oktaAuth.getOriginalUri();
    if (!originalUri) {
      this._oktaAuth.setOriginalUri('/');
    }

    const tokens: Tokens = await this._widget.showSignInToGetTokens({
      el: '#okta-signin-container',
    });
    this._oktaAuth.handleLoginRedirect(tokens);
    this._widget.hide();
  }
}
