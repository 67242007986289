import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
// TODO: Fix scoping for projects
import { BottomNavigation, DisplayErrors, DocumentsReview, MultiUploadPanelsEvent, StepType } from '@dmv/public/shared/http';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-mv-wrapper',
  styleUrls: ['./mv-wrapper.component.scss'],
  templateUrl: './mv-wrapper.component.html',
})
export class MvWrapperComponent {
  @Input() public currentStep: StepType = 'survey';
  @Input() public documentName = '';
  @Input() public displayErrors: DisplayErrors;
  @Input() public enhanced = false;
  @Input() public reviewed = false;
  @Input() public mvLink = null;
  @Input() public reviewObjects: DocumentsReview | undefined;
  @Input() public showToast = false;
  @Input() public stepConfiguration: BottomNavigation = null;
  @Input() public transactionId: null;
  @Input() public transactionType;
  @Input() public uploadDocuments = null;

  @Output() public readonly onContinueClick: EventEmitter<void> = new EventEmitter();
  @Output() public readonly onPreviousClick: EventEmitter<void> = new EventEmitter();
  @Output() public readonly onReviewedUploads: EventEmitter<boolean> = new EventEmitter();
  @Output() public readonly onSubmitSection: EventEmitter<void> = new EventEmitter();
  @Output() public readonly onUploadEventComplete: EventEmitter<MultiUploadPanelsEvent> = new EventEmitter();
  @Output() public readonly onUploadsComplete: EventEmitter<boolean> = new EventEmitter();

  public focusEvent = 'dmv-focus-main-content-event';

  public submitSection() {
    this.onSubmitSection.emit();
  }

  public reviewedUploads(event: boolean) {
    this.onReviewedUploads.emit(event);
  }

  public continueClick() {
    this.onContinueClick.emit();
  }

  public previousClick() {
    this.onPreviousClick.emit();
  }

  public uploadEventComplete(event: MultiUploadPanelsEvent) {
    this.onUploadEventComplete.emit(event);
  }

  public uploadsComplete(event: boolean) {
    this.onUploadsComplete.emit(event);
  }

  public handleClickDownload() {
    window.open(this.mvLink, '_blank');
  }
}
