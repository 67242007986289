import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { MultiUploadPanelsAction, MultiUploadPanelsEvent, UploadDocumentDetail, UploadItem } from '@dmv/public/shared/http';
import { UploadPanelComponent } from '../upload-panel/upload-panel.component';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'dmv-shared-feature-multi-upload-panels',
  styleUrls: ['./multi-upload-panels.component.scss'],
  templateUrl: './multi-upload-panels.component.html',
})
export class MultiUploadPanelsComponent {
  @Input() public transactionId;
  @Input() public transactionType;
  @Input() public transactionData: Map<string, string>;
  @Input() public required: boolean;
  @Input() public uploadItems: UploadItem[];
  @Input() public newUpload = true;
  @Input() public indexBias = 0;
  @Output() public readonly checkDocTypeChangeEligible = new EventEmitter<UploadDocumentDetail>();
  @Output() public readonly docTypeChangeClicked = new EventEmitter<UploadItem>();

  @ViewChildren(UploadPanelComponent) public uploadPanels: QueryList<UploadPanelComponent>;

  @Output() public readonly event = new EventEmitter<MultiUploadPanelsEvent>();
  @Output() public readonly panelItemUploaded = new EventEmitter<UploadDocumentDetail>();
  @Output() public readonly allDocumentUploadsValid = new EventEmitter<boolean>();
  @Output() public readonly uploadDetailReuploaded = new EventEmitter<UploadDocumentDetail>();

  private _uploadItemLoading = false; //TODO: remove when all sections are using survey section or other continue logic other than proof section's

  public validate(notSetStatus?: boolean): boolean {
    let valid = true;
    const arr = this.uploadPanels.toArray();
    for (const el of arr) {
      if (!el.isValid(notSetStatus)) {
        valid = false;
      }
    }
    this.allDocumentUploadsValid.emit(valid && !this._uploadItemLoading);

    return valid;
  }

  public uploadItemChanged($event: UploadItem, _index: number) {
    this.event.emit(
      new MultiUploadPanelsEvent($event.uploaded ? MultiUploadPanelsAction.COMPLETED : MultiUploadPanelsAction.FILE_UPLOADED, $event),
    );
  }

  public itemUploaded($event: UploadDocumentDetail) {
    this.panelItemUploaded.emit($event);
  }

  public onUploadItemLoadingChange($event: boolean) {
    this._uploadItemLoading = $event;
    this.validate(true);
  }

  public onUploadDetailReuploaded($event: UploadDocumentDetail) {
    this.uploadDetailReuploaded.emit($event);
  }

  public trackByFunction(index: number, item: UploadItem): string {
    // If each item has a unique ID, return that. Otherwise, return whatever makes sense to track by.
    return item.id;
  }

  public onCheckDocTypeChangeEligible($event: UploadDocumentDetail) {
    this.checkDocTypeChangeEligible.emit($event);
  }

  public onDocTypeChangeClicked($event: UploadItem) {
    this.docTypeChangeClicked.emit($event);
  }
}
