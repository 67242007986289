import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AnswerEvent, AnswerPrompt, DEFAULT_QUESTION, Question } from '@dmv/public/shared/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SurveyDataService } from '../../survey-data.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-text-question',
  styleUrls: ['./text-question.component.scss'],
  templateUrl: './text-question.component.html',
})
export class TextQuestionComponent {
  @Input() public question?: Question = { ...DEFAULT_QUESTION, type: 'text' };
  @Output() public readonly textBlur: EventEmitter<AnswerEvent> = new EventEmitter<AnswerEvent>();

  public form: FormGroup | undefined;

  constructor(private readonly fb: FormBuilder, private readonly _surveyDataService: SurveyDataService) {
    this.form = this.fb.group({
      answer: [this.question.answers[0]],
    });
  }

  public onBlur($event: Event, questionId: string, answerPrompts: AnswerPrompt[]) {
    const answer = $event.target as HTMLSelectElement;
    const value: string = answer.value;
    const answerPrompt: AnswerPrompt | undefined = answerPrompts[0];
    answerPrompt.value = value;

    if (value) {
      this.question.answers = this._surveyDataService.updateAnswers(this.question.answers, answerPrompt.value, this.question.type);
      this.textBlur.emit({ question: this.question, questionId, questionType: 'text', selection: answerPrompt });
    }
  }
}
