<ng-container *ngIf="question?.id">
  <section [ngSwitch]="question.type">
    <ng-container *ngSwitchCase="'radio'">
      <dmv-radio-question
        [displayAnswer]="displayAnswer"
        [question]="question"
        (radioBlur)="onAnswerSelection($event)"
      ></dmv-radio-question>
    </ng-container>
    <ng-container *ngSwitchCase="'select'">
      <dmv-select-question [question]="question" (selectBlur)="onAnswerSelection($event)"></dmv-select-question>
    </ng-container>
    <ng-container *ngSwitchCase="'text'">
      <dmv-text-question [question]="question" (textBlur)="onAnswerSelection($event)"></dmv-text-question>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <!-- I could not find any support for a multi value switch case so checkbox will be the default -->
      <dmv-checkbox-question
        [currentPoints]="currentPoints"
        [question]="question"
        (checkboxBlur)="onAnswerSelection($event)"
      ></dmv-checkbox-question>
    </ng-container>
  </section>
  <!--    <dmv-error *ngIf="question.errorMessage" [errorMessage]="question.errorMessage"></dmv-error>-->
</ng-container>
