<mat-accordion class="dmv-accordian">
  <mat-expansion-panel
    class="mat-elevation-z0"
    [ngClass]="{
      'expand-up': expandDirection === 'up',
      'no-title-border': !showHeader,
      'hide-border': hideBorder
    }"
    [expanded]="panelOpenState"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    hideToggle
  >
    <mat-expansion-panel-header *ngIf="showHeader">
      <mat-panel-title>
        <ng-container *ngIf="showInfo">
          <mat-icon class="mt-1" data-testid="expand-button" aria-hidden="false">
            {{ !panelOpenState ? 'expand_more' : 'expand_less' }}</mat-icon
          >
          <mat-icon class="mr-2 mt-1">info_outline</mat-icon>
        </ng-container>
        {{ showAllLabel ? showAllLabel : panelOpenState ? hideLabel : showLabel }}
        <mat-icon *ngIf="!showInfo" aria-hidden="false"> {{ panelOpenState ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-content></ng-content>
  </mat-expansion-panel>
</mat-accordion>
