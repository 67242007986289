<section *ngIf="question">
  <div class="survey-question-md" data-testid="survey-question">{{ question.question }}</div>
  {{ question.warning }}
  <div class="warning-container" *ngIf="question.warning" [innerHTML]="question.warning" data-testid="warning-container"></div>
  <div
    class="additional-info-container"
    *ngIf="question.additionalInfo"
    [innerHTML]="question.additionalInfo"
    data-testid="additional-info-container"
  ></div>
  <div class="form-group">
    <select
      class="form-control"
      [id]="question.id"
      [value]="question.answers[0]"
      (change)="onSelection($event, question.id, question.answerPrompts)"
      attr.aria-labelledby="{{ question.question }}"
    >
      <option
        *ngFor="let answerPrompt of question.answerPrompts"
        [selected]="question.answers[0] === answerPrompt.value"
        [value]="answerPrompt.value"
        data-testid="select-input-option"
      >
        <div>{{ answerPrompt.prompt }}</div>
      </option>
    </select>
  </div>
</section>
