import { AfterViewInit, Directive, forwardRef, OnDestroy } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { FormViewAdapter, NGRX_FORM_VIEW_ADAPTER } from 'ngrx-forms';
import { Subscription } from 'rxjs';

// tslint:disable:directive-selector
// tslint:disable:directive-class-suffix
// necessary since material 2 does not properly export the mat-select as a NG_VALUE_ACCESSOR
@Directive({
  // Will not work if this is renamed to dmv...
  // eslint-disable-next-line @angular-eslint/directive-selector
  providers: [
    {
      multi: true,
      provide: NGRX_FORM_VIEW_ADAPTER,
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => NgrxMatSelectViewAdapter),
    },
  ],
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-select[ngrxFormControlState]',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class NgrxMatSelectViewAdapter implements FormViewAdapter, AfterViewInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _value: any;
  private readonly _subscriptions: Subscription[] = [];

  constructor(private readonly _matSelect: MatSelect) {}

  public ngAfterViewInit() {
    this._subscriptions.push(
      this._matSelect.options.changes.subscribe(() => {
        Promise.resolve().then(() => this._matSelect.writeValue(this._value));
      }),
    );
  }

  public ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setViewValue(value: any) {
    this._value = value;

    // we have to verify that the same value is not set again since that would
    // cause focus to get lost on the select since it tries to focus the active option
    const selectedOption = this._matSelect.selected;

    if (selectedOption) {
      if (Array.isArray(selectedOption) && Array.isArray(value)) {
        if (value.length === selectedOption.length && value.every((v, i) => v === selectedOption[i])) {
          return;
        }
      } else if (!Array.isArray(selectedOption)) {
        if (value === selectedOption.value) {
          return;
        }
      }
    }

    // because the options are potentially updated AFTER the value (because of their order in the DOM),
    // setting the value has to be deferred, otherwise we might select an option which is not available yet.
    Promise.resolve().then(() => this._matSelect.writeValue(value));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setOnChangeCallback(fn: any) {
    this._matSelect.registerOnChange(value => {
      this._value = value;
      fn(value);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setOnTouchedCallback(fn: any) {
    this._matSelect.registerOnTouched(fn);
  }

  public setIsDisabled(isDisabled: boolean) {
    this._matSelect.setDisabledState(isDisabled);
  }
}
