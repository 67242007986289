import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { SurveyWrapperBaseComponent } from './survey-wrapper-base.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-survey-wrapper',
  styleUrls: ['./survey-wrapper.component.scss'],
  templateUrl: './survey-wrapper.component.html',
})
export class SurveyWrapperComponent extends SurveyWrapperBaseComponent {
  constructor(protected readonly _changeDetectorRef: ChangeDetectorRef) {
    super(_changeDetectorRef);
  }
}
