<section id="survey-wrapper" *ngIf="questions">
  <dmv-toast [focusMonitor]="focusMonitor$" [setFocus]="true" [show]="error !== ''" [showIcon]="true" type="error" heading="Error:">
    <ol class="toast-content-list toast-content-list-error" data-testid="dmvValidationErrorMessage">
      <li>{{ error }}</li>
    </ol>
  </dmv-toast>

  <dmv-survey [displayAnswer]="displayAnswer" [question]="questions[questionIndex]" (inputBlur)="onInputBlur($event)"></dmv-survey>
</section>
<dmv-toast
  [setFocus]="true"
  [float]="true"
  [show]="bottomError !== ''"
  [autohide]="true"
  [delay]="5000"
  (toastHidden)="bottomError = ''"
  heading="Error"
>
  <div>
    <ol class="toast-content-list" data-testid="dmvValidationErrorMessage">
      <li>{{ bottomError }}</li>
    </ol>
  </div>
</dmv-toast>

<dmv-footer></dmv-footer>

<section id="step-navigation">
  <dmv-bottom-navigation
    [showContinue]="true"
    [showPrevious]="showPreviousButton"
    [showSubmit]="false"
    (continueClick)="onContinueClick()"
    (previousClick)="onPreviousClick()"
  >
  </dmv-bottom-navigation>
</section>
