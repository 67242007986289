<section class="terminal-page" data-testid="terminal-page-container">
  <mat-icon class="terminal-page-alert" *ngIf="showAlert" data-testid="terminal-page-alert-icon">report</mat-icon>
  <p class="terminal-page-summary font-size-30">
    {{ summary }}
  </p>
  <mat-divider class="terminal-page-divider"></mat-divider>
  <ng-content></ng-content>
  <div class="footer-container">
    <dmv-footer></dmv-footer>
  </div>
  <dmv-bottom-navigation
    [continueText]="continueText"
    [previousText]="previousText"
    [showContinue]="true"
    [showPrevious]="showPrevious"
    (continueClick)="continueClick()"
    (previousClick)="previousClick()"
    data-testid="start-over-button"
  ></dmv-bottom-navigation>
</section>
