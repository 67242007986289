import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AnswerEvent, AnswerPrompt, DEFAULT_QUESTION, Question } from '@dmv/public/shared/http';
import { SurveyDataService } from '../../survey-data.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-select-question',
  styleUrls: ['./select-question.component.scss'],
  templateUrl: './select-question.component.html',
})
export class SelectQuestionComponent {
  @Input() question?: Question = { ...DEFAULT_QUESTION, type: 'select' };
  @Output() readonly selectBlur: EventEmitter<AnswerEvent> = new EventEmitter<AnswerEvent>();

  constructor(private readonly _surveyDataService: SurveyDataService) {}

  onSelection($event: Event, questionId: string, answerPrompts: AnswerPrompt[]) {
    const answer = $event.target as HTMLSelectElement;
    const value: string = answer.value;
    // eslint-disable-next-line eqeqeq
    const answerPrompt: AnswerPrompt | undefined = answerPrompts.find(prompt => prompt.value == value);
    if (answerPrompt) {
      this.question.answers = this._surveyDataService.updateAnswers(this.question.answers, answerPrompt.value, this.question.type);
      this.question.totalPoints = answerPrompt.metadata.points;

      this.selectBlur.emit({ question: this.question, questionId, questionType: 'select', selection: answerPrompt });
    }
  }
}
