<dmv-accordion
  [showAllLabel]="'Must bring required documents and payment'"
  [showIcon]="true"
  [hideBorder]="true"
  [panelOpenState]="panelOpenState"
>
  <div class="fee-notice" *ngIf="showFeeNotice" [innerHTML]="feeNotice" data-testid="show-fee-notice"></div>
  <div data-testid="appointment-originals-required">
    You will need to bring in originals of the documents that you have already uploaded:
    <ul>
      <li *ngFor="let docType of documentTypes" data-testid="dashboard-appointment-document-listitem" role="listitem">
        {{ docType.name }} {{ docType.qualifier ? '(' + docType.qualifier + ')' : '' }}
      </li>
      <li *ngIf="showSocialSecurityNotice" data-testid="dashboard-show-social-security-notice-listitem" role="listitem">
        Social Security Card*
      </li>
    </ul>
    <p *ngIf="showSocialSecurityNotice" data-testid="dashboard-show-social-security-notice">
      *If you bring your Social Security card to your in-person visit, you can receive an Enhanced or REAL ID. If you lost your card, you
      can request a free replacement card from the
      <a href="https://www.ssa.gov/number-card/replace-card" target="_blank"
        >SSA website <mat-icon class="open-new-icon">open_in_new</mat-icon></a
      >.
    </p>
  </div>
</dmv-accordion>
