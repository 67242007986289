import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Boxed, unbox } from 'ngrx-forms';
import { FindTransactionsParams } from '../models/findTransactionsParams';
import { RegularExpressionsConstants } from '../models/regular-expressions.constants';

export interface PlateValidationError {
  invalid: boolean;
}

export class ValidateUtil {
  // The value should be between 1900 and the current year + 1
  public static validateModelYear(year) {
    if (isNaN(year) || year.length !== 4) {
      return false;
    }
    const nextYear = new Date().getFullYear() + 1;
    if (year < 1900 || year > nextYear) {
      return false;
    }

    return true;
  }

  // Disable fields have value, allow empty fields to input, and return whether fields invalid
  public static validateAndEnableInvalidFields(form: UntypedFormGroup, fields: string[]): boolean {
    if (!form || !fields || fields.length === 0) {
      return false;
    }
    let valid = true;
    for (const field of fields) {
      const fieldContrl = form.get(field);
      // Skip if fieldContrl does not exist
      if (!fieldContrl) {
        continue;
      }
      if (!fieldContrl.value) {
        fieldContrl.enable();
        valid = false;
      } else {
        fieldContrl.disable();
      }
    }

    return valid;
  }

  public static validateHttpParams(params: FindTransactionsParams) {
    const validParams = {};

    Object.keys(params).forEach(key => {
      const val = params[key];
      if (val !== null && val !== undefined && val !== '') {
        validParams[key] = val;
      }
    });

    return validParams;
  }

  public static validateEmails(control: UntypedFormGroup) {
    return control.get('email').value.toLowerCase() === control.get('confirmEmail').value.toLowerCase()
      ? null
      : { notEqual: 'Email & Confirm email should match' };
  }

  public static ValidateVin(control: AbstractControl): Record<string, any> | null {
    const invalidError = { invalid: true };
    const value = control.value;
    if (!control.value) {
      return null;
    }
    const vinRegEx = /^[a-zA-Z0-9]{5,17}$/;
    const valid = vinRegEx.test(value);

    return valid ? null : invalidError;
  }

  public static ValidatePlateNgrx(value: string): PlateValidationError {
    const invalidError = { invalid: true };
    if (!value) {
      return null;
    }
    const plateRegEx = /^[A-Za-z0-9 _]{1,8}$/;
    const valid = plateRegEx.test(value);

    return valid ? null : invalidError;
  }

  public static ValidatePlate(control: AbstractControl): Record<string, any> | null {
    const invalidError = { invalid: true };
    const value = control.value;
    if (!control.value) {
      return null;
    }
    const plateRegEx = /^[A-Za-z0-9 _]{1,8}$/;
    const valid = plateRegEx.test(value);

    return valid ? null : invalidError;
  }

  public static ValidateClientId(value: string): ValidationErrors | null {
    const invalidError = { clientId: 'Not a valid client ID' };
    if (!value) {
      return null;
    }

    if (!RegExp(RegularExpressionsConstants.LICENSE_ID).test(value)) {
      return invalidError;
    }

    const set1 = `00${value.substring(0, 3)}`;
    const set2 = value.substring(3);
    let total = 0;
    for (let i = 0; i < 5; i++) {
      total += (parseInt(set1.charAt(i), 10) + parseInt(set2.charAt(i), 10)) * (6 - i);
    }

    return total % 10 === parseInt(value.charAt(8), 10) ? null : invalidError;
  }
}

export function GreaterThan(target: number | null) {
  return <T extends number | string | Boxed<string> | any[] | Boxed<any[]> | null | undefined>(value: T): ValidationErrors => {
    value = unbox(value);
    const invalidError = { greaterThan: `Must be greater than ${target}` };
    if (isNaN(value as number) || isNaN(target) || (value as number) <= target) {
      return invalidError;
    }

    return null;
  };
}
