<dmv-accordion
  *ngIf="currentConfiguration?.completedSteps?.show; else dividerBlock"
  [showLabel]="'COMPLETED STEPS'"
  [hideLabel]="'COMPLETED STEPS'"
  [expandDirection]="'down'"
>
  <ul class="completed-steps" data-testid="completed-steps">
    <li *ngFor="let step of currentConfiguration.completedSteps.steps"><mat-icon aria-hidden="false">check_circle</mat-icon> {{ step }}</li>
  </ul>
</dmv-accordion>
<ng-template #dividerBlock>
  <mat-divider></mat-divider>
</ng-template>
