import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TransactionDocumentType } from '@dmv/common';
import { FEE_NOTICE } from '../../static/dmv-links';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-dashboard-document-types',
  styleUrls: ['./dashboard-document-types.component.scss'],
  templateUrl: './dashboard-document-types.component.html',
})
export class DashboardDocumentTypesComponent {
  @Input() public documentTypes: TransactionDocumentType[] = [];
  @Input() public panelOpenState = false;
  @Input() public showFeeNotice = false;
  @Input() public showSocialSecurityNotice = false;

  public readonly feeNotice = FEE_NOTICE;
}
