import { UntypedFormGroup } from '@angular/forms';
import { AppointmentStatus, Branch, TransactionStatus } from '@dmv/common';
import { ContactPreference, ContactPreferenceLabel, WorkFlowType } from '@dmv/core';
import { BaseTransactionStatus, ContactPreferenceType } from '@dmv/public/shared/http';
export interface SchedulingInfo {
  firstName: string;
  branchCity: string;
  branchAddress: string;
  appointmentTime: string;
  cancelByDate?: Date;
  contactPreferenceType?: ContactPreferenceType;
  appointmentStatus: AppointmentStatus;
  status: TransactionStatus | BaseTransactionStatus;
  id: number;
  transactionId?: string;
}

export interface AppointmentDetails extends SchedulingDetail {
  contactPreference: ContactPreference;
}
export interface TimeSlot {
  startTime: string;
  endTime: string;
}

export interface SchedulingDetail {
  branch: Branch;
  date: string;
  timeSlot: TimeSlot;
  previousStartTime?: string;
}

export interface SchedulingCompletePayload {
  complete: boolean;
  schedulingDetails: SchedulingDetail;
}

export interface AppointmentReservation {
  publicId: string; // appointmentId
  title: string;
  branch: Branch;
  services: QmaticService[];
  start: string;
  end: string;
  notes: string;
}

export interface QmaticService {
  duration: number;
  name: string;
  publicId: string;
}

export interface Appointment {
  publicId: string;
  title: string;
  start: string;
  end: string;
  branch: Branch;
  services: QmaticService[];
  notes: string;
}

export type SchedulingStepType = 'location' | 'date' | 'time' | 'summary';

export interface SchedulingStep {
  id: SchedulingStepType;
  title: string;
  stepNumber: number;
  route: string[];
  showPrevious: boolean;
  showContinue: boolean;
}

export const schedulingSteps: SchedulingStep[] = [
  {
    id: 'location',
    route: ['location'],
    showContinue: false,
    showPrevious: false,
    stepNumber: 0,
    title: 'Select your DMV location',
  },
  {
    id: 'date',
    route: ['date'],
    showContinue: false,
    showPrevious: false,
    stepNumber: 1,
    title: 'Select your appointment date',
  },
  {
    id: 'time',
    route: ['time'],
    showContinue: false,
    showPrevious: false,
    stepNumber: 2,
    title: 'Select your appointment time',
  },
];

export interface SchedulingWorkFlowStep {
  id: WorkFlowType;
  stepperTitle: string;
  pageTitle?: string;
  pageSubTitle?: string;
  step: number;
  status: 'to-do' | 'active' | 'completed';
  route: string[];
  optional?: boolean;
  form?: UntypedFormGroup;
  nextButtonText?: string;
  hidePrevious?: boolean;
  hideContinue?: boolean;
  hideStepper?: boolean;
  hideNavigation?: boolean;
}

export const schedulingWorkflow: SchedulingWorkFlowStep[] = [
  {
    hideNavigation: true,
    hideStepper: true,
    id: 'scheduling',
    pageTitle: '',
    route: ['scheduling'],
    status: 'to-do',
    step: 0,
    stepperTitle: 'Choose Appointment',
  },
  {
    hideStepper: true,
    id: 'reservation-summary',
    route: ['reservation-summary'],
    status: 'to-do',
    step: 1,
    stepperTitle: 'Schedule',
  },
  {
    hideContinue: true,
    hidePrevious: true,
    hideStepper: true,
    id: 'confirmation',
    route: ['confirmation'],
    status: 'to-do',
    step: 2,
    stepperTitle: 'Confirm',
  },
];

export const contactPreferences: ContactPreference[] = [
  {
    icon: 'fas fa-phone-alt',
    label: ContactPreferenceLabel.PHONE,
    type: 'phone',
  },
  {
    icon: 'far fa-comment-alt',
    label: ContactPreferenceLabel.TEXT_SMS,
    type: 'sms',
  },
  {
    icon: 'far fa-envelope',
    label: ContactPreferenceLabel.EMAIL,
    type: 'email',
  },
];

export enum SchedulingErrorCode {
  'TIME_SLOT_BOOKED' = 'TIME_SLOT_BOOKED',
}
export interface SchedulingError {
  errorCode: SchedulingErrorCode;
  message: string;
}
