import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-active-step-requests',
  styleUrls: ['./active-step-requests.component.scss'],
  templateUrl: './active-step-requests.component.html',
})
export class ActiveStepRequestsComponent {
  // TODO: These need proper types once we can update OP and REAL ID
  @Input() public documents: any[] | null = null;
  @Input() public additionalDocuments: any[] | null = null;
}
