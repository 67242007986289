export const COLUMN_NAMES = {
  AMENDMENT: 'Amendment',
  APPLICANT_NAME: 'Applicant Name',
  APPOINTMENT_DATE: 'Appointment Date',
  CID: 'CID',
  CLIENT_ID: 'Client ID',
  CREATED_ON: 'Created On',
  DIN_NUMBER: 'DIN Number',
  DOCUMENT_REQUESTED: 'Document Requested',
  EMAIL: 'Email',
  FACILITY: 'Facility',
  ID_REQUESTED: 'ID Requested',
  LICENSE_TYPE_REQUESTED: 'License Type Requested',
  NAME: 'Name',
  PARTNER: 'Partner',
  PERMIT_TYPE_REQUESTED: 'Permit Type Requested',
  PHONE: 'Phone',
  REJECTED: 'Rejected',
  RELEASE_DATE: 'Release Date',
  SUBMITTED_ON: 'Submitted On',
  TRANSACTION_ID: 'Transaction ID',
  TRANSACTION_STATUS: 'Transaction Status',
  VEHICLE: 'Vehicle',
  VIN_NUMBER: 'VIN/SIN/HIN#',
};

type COLUMN = Record<string, string[]>;

export const WORKFLOW_COLUMNS: COLUMN = {
  'DOCCS Non Driver ID': [
    COLUMN_NAMES.SUBMITTED_ON,
    COLUMN_NAMES.APPLICANT_NAME,
    COLUMN_NAMES.DIN_NUMBER,
    COLUMN_NAMES.TRANSACTION_ID,
    COLUMN_NAMES.FACILITY,
    COLUMN_NAMES.RELEASE_DATE,
  ],
  'Original Permit': [
    COLUMN_NAMES.CREATED_ON,
    COLUMN_NAMES.APPOINTMENT_DATE,
    COLUMN_NAMES.PERMIT_TYPE_REQUESTED,
    COLUMN_NAMES.TRANSACTION_ID,
    COLUMN_NAMES.APPLICANT_NAME,
  ],
  'Original Registration': [
    COLUMN_NAMES.CREATED_ON,
    COLUMN_NAMES.NAME,
    COLUMN_NAMES.CID,
    COLUMN_NAMES.VIN_NUMBER,
    COLUMN_NAMES.VEHICLE,
    COLUMN_NAMES.TRANSACTION_ID,
    COLUMN_NAMES.EMAIL,
    COLUMN_NAMES.PHONE,
  ],
  'Upgrade to Enhanced or REAL ID': [
    COLUMN_NAMES.CREATED_ON,
    COLUMN_NAMES.APPLICANT_NAME,
    COLUMN_NAMES.CID,
    COLUMN_NAMES.APPOINTMENT_DATE,
    COLUMN_NAMES.TRANSACTION_ID,
    COLUMN_NAMES.DOCUMENT_REQUESTED,
  ],
};
