<div class="row">
  <div class="col-xs-12 col-lg-8 offset-lg-2" [dmvFocus]="true" tabindex="-1" focusEventName="{{ focusEvent }}">
    <section class="checklist-elements">
      <section class="workflow-title">
        <h1>{{ title }}</h1>
      </section>
      <section class="workflow-info" data-testid="workflow-info">
        <div class="workflow-text" [innerHTML]="workflowInfo"></div>
      </section>
      <section class="workflow-section">
        <div class="section-content" *ngFor="let item of transformedSections">
          <!-- Accordion for sections with subsections -->
          <mat-accordion *ngIf="item.parentSection">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon [ngClass]="{ 'completed-icon': item.complete }" matListIcon>{{
                    item.complete ? 'check_circle' : 'radio_button_unchecked'
                  }}</mat-icon>
                  <h2 class="section-title">{{ item.parentSection }}</h2>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-list>
                <mat-list-item *ngFor="let section of item.sections">
                  <dmv-section-v2
                    [complete]="section.status === 'complete'"
                    [standaloneSection]="false"
                    [title]="section.checklistTitle || section.description"
                    (restartClick)="onRestartClick(section)"
                    (sectionClick)="onSectionClick(section)"
                  ></dmv-section-v2>
                </mat-list-item>
              </mat-list>
            </mat-expansion-panel>
          </mat-accordion>

          <!-- Standalone sections without subsections -->
          <mat-card *ngIf="!item.parentSection" appearance="outlined">
            <mat-card-content class="section-card-container">
              <dmv-section-v2
                [complete]="item.section.status === 'complete'"
                [standaloneSection]="true"
                [title]="item.section.checklistTitle || item.section.description"
                (restartClick)="onRestartClick(item.section)"
                (sectionClick)="onSectionClick(item.section)"
              ></dmv-section-v2>
            </mat-card-content>
          </mat-card>
        </div>
      </section>
      <div class="footer-container">
        <dmv-footer></dmv-footer>
      </div>
    </section>
  </div>
</div>
