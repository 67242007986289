<div>
  <div class="d-flex justify-content-between header-container">
    <div class="mr-auto">
      <h1>
        Welcome back to your dashboard<span *ngIf="userAuthInfo$ | async as authUser" data-testid="user-info"
          >, {{ authUser.user?.firstName }}</span
        >!
      </h1>
    </div>
    <div class="align-self-center">
      <a class="print-link font-size-13" href="#" onclick="window.print(); return false" aria-label="print dashboard">
        <mat-icon class="print-icon" data-testid="print-button">print</mat-icon>print
      </a>
    </div>
  </div>
  <div class="details-container" data-testid="application-message">
    {{ applicationMsg }}
    <span class="font-weight-bold" data-testid="application-title">{{ appTitle }}</span>
    <ng-container *ngIf="transactionId || status !== statusTypes.DRAFT">
      <div>
        <span class="transaction-id font-size-14" *ngIf="transactionId"
          >Transaction ID: <span class="font-weight-bold" data-testid="transaction-id">{{ transactionId }}</span></span
        >
        <span class="transaction-id font-size-14" *ngIf="transactionId && status !== statusTypes.DRAFT"> | </span>
        <span class="submitted-on-date font-size-14" *ngIf="status !== statusTypes.DRAFT" data-testid="submitted-date"
          >Submitted on {{ submittedOn | date: 'MM/dd/yyyy' }}</span
        >
      </div>
    </ng-container>
  </div>
  <p>
    Status:
    <span class="font-weight-bold" [ngClass]="statusStyle" data-testid="status">
      {{ statusText }}
    </span>
    <span
      class="font-weight-bold"
      *ngIf="status === 'Rejected' || status === 'SuccessfulVisit' || status === 'Cancelled'"
      [ngClass]="statusStyle"
      data-testid="updated-at"
    >
      on {{ updatedAt | date: 'MM/dd/yyyy' }}</span
    >
  </p>
</div>
