import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RealIdEdlDTO } from '@dmv/core';
import { OriginalPermitDTO } from '@dmv/public/shared/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-barcode',
  styleUrls: ['./barcode.component.scss'],
  templateUrl: './barcode.component.html',
})
export class BarcodeComponent implements OnInit, AfterViewInit {
  @ViewChild('barCodeImage') public barCodeImage: ElementRef;

  public errorMessage?: string;
  public transactionId?: string;
  public applicationName: string;
  public transaction$: Observable<RealIdEdlDTO | OriginalPermitDTO>;

  constructor(@Inject('barcodePrefix') private readonly _barcodePrefix: string, private readonly _route: ActivatedRoute) {}

  public ngOnInit(): void {
    switch (this._route.snapshot.params.application) {
      case 'original-permit':
        this.applicationName = 'NY State Learner Permit';
        break;
      case 'real-id-edl-upgrade':
        this.applicationName = 'Enhanced or Real ID';
        break;
      case 'exchange-license':
        this.applicationName = 'Out of State License Exchange';
        break;
      case 'register-vehicle':
        this.applicationName = 'Register Vehicle';
        break;
      default:
        this.applicationName = null;
    }

    this.transactionId = this._route.snapshot.params.transactionId;
  }

  public ngAfterViewInit(): void {
    this._route.queryParams
      .pipe(
        filter(params => params.barcode),
        map(params => {
          this.barCodeImage.nativeElement.src = `${this._barcodePrefix}${params.barcode}.jpg`;
        }),
      )
      .subscribe();
  }
}
