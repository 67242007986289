import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RolePermissionsModule } from '@libs/role-permissions';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgrxFormsModule } from 'ngrx-forms';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { AddressComponent } from './components/address/address.component';
import { BaseInfoModalComponent } from './components/base-info-modal/base-info-modal.component';
import { BaseLoginComponent } from './components/base-login/base-login.component';
import { BaseTableComponent } from './components/base-table/base-table.component';
import { BaseComponent } from './components/base/base.component';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DocAiChecklistComponent } from './components/doc-ai-checklist/doc-ai-checklist.component';
import { HeaderComponent } from './components/header/header.component';
import { ModalCloseButtonComponent } from './components/modal/close-button/close-button.component';
import { PdfModalComponent } from './components/pdf-modal/pdf-modal.component';
import { PdfViewerWrapperComponent } from './components/pdf-viewer/pdf-viewer-wrapper.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CopyToClipboardDirective } from './directives';
import { MaterialModule } from './material.module';
import { NumberToThousandsPipe, PhonePipe } from './pipes';
import {
  CountyService,
  DoccsNdidService,
  DocumentService,
  OriginalRegistrationService,
  PaymentService,
  PdfService,
  PermitService,
  RegistrationService,
} from './services';
import { DisableFormControlDirective } from './utils';

@NgModule({
  declarations: [
    AddressComponent,
    BaseLoginComponent,
    PdfModalComponent,
    HeaderComponent,
    PdfViewerWrapperComponent,
    DocAiChecklistComponent,
    BaseComponent,
    BaseInfoModalComponent,
    BaseTableComponent,
    CustomToastComponent,
    DatePickerComponent,
    CopyToClipboardDirective,
    DisableFormControlDirective,
    SpinnerComponent,
    PhonePipe,
    ModalCloseButtonComponent,
    NumberToThousandsPipe,
  ],
  exports: [
    AddressComponent,
    BaseLoginComponent,
    PdfModalComponent,
    HeaderComponent,
    BaseInfoModalComponent,
    DatePickerComponent,
    CustomToastComponent,
    CopyToClipboardDirective,
    DisableFormControlDirective,
    SpinnerComponent,
    PhonePipe,
    ModalCloseButtonComponent,
    NumberToThousandsPipe,
  ],
  imports: [
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgrxFormsModule,
    NgxExtendedPdfViewerModule,
    NgxSpinnerModule,
    RolePermissionsModule,
  ],
  providers: [
    CountyService,
    RegistrationService,
    OriginalRegistrationService,
    PaymentService,
    DocumentService,
    PermitService,
    PdfService,
    DoccsNdidService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {
  constructor() {
    //this line and setup is CRUCIAL to our upload functionality and password protected pdf checks
    GlobalWorkerOptions.workerSrc = '/assets/pdfjs/pdf.worker.min.mjs';
  }
}

export * from './components/address/address.component';
export * from './components/address/states.constant';
export * from './components/base-info-modal/base-info-modal.component';
export * from './components/base-login/base-login.component';
export * from './components/base-table/base-table.component';
export * from './components/base/base.component';
export * from './components/custom-toast/custom-toast.component';
export * from './components/date-picker/date-picker.component';
export * from './components/header/header.component';
export * from './components/modal/close-button/close-button.component';
export * from './components/pdf-modal/pdf-modal.component';
export * from './components/spinner/spinner.component';
